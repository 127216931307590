import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { CameraAlt, Description } from '@mui/icons-material';
import LazyImg from '../../../../components/common/LazyImg';
import { Text, Span } from '../../../../components/common/Texts';
import { ChatContext } from '../../../../contexts/chat/ChatProvider';
import { socketIO } from '../../../../helpers/connectionSocket.helper';
import { MIMETYPE_CASES } from '../../../../utils/file.utils';
import { formatDateTime } from '../../../../utils/dates/dates.utils';
import { useQueryParams } from '../../../../hooks/useQueryParams';
import { toTitleCase } from '../../../../helpers/titleCase.helper';

const ChatRoomCard = (props) => {
    const { room, userId } = props;

    const { latestMessage } = room;

    const params = useQueryParams();

    const defaultRoomId = params.get('room');

    const history = useHistory();

    const {
        socketId,
        setSocketId,
        currentRoom,
        setCurrentRoom,
        getRoomMessages,
        checkUserStatus,
        setMessagesAsSeen,
        deleteNotification,
        notifications,
        setMessages,
        setReciverConnection,
        pageRef,
    } = useContext(ChatContext);

    const [recentMessage, setRecentMessage] = useState(
        latestMessage?.messageBody
    );
    const [timeSent, setTimeSent] = useState(
        formatDateTime(latestMessage?.createdAt)
    );
    const [unseenLength, setUnseenLength] = useState(
        room?.unseenMessages?.amount
    );
    const [unseenMessages, setUnseenMessages] = useState(
        room?.unseenMessages?.messages
    );
    const [connection, setConnection] = useState(room?.online);

    useEffect(() => {
        socketIO.on('userStatusChange', ({ user_id }) => {
            if (user_id === room?.id) {
                socketIO.emit('getUserStatus', { user_id });
                socketIO.on('userStatus', ({ status }) => {
                    setConnection(status);
                    setReciverConnection(status);
                });
            }
        });
    }, []);

    useEffect(() => {
        socketIO.on('lastMessage', (lastMessage) => {
            if (lastMessage?.room_id === room?.roomId) {
                setRecentMessage(lastMessage?.message_body);
                setTimeSent(formatDateTime(lastMessage?.created_at));
            }
        });

        socketIO.on('unseenMessages', (unseenMessages) => {
            if (unseenMessages?.room_id === room?.roomId) {
                setUnseenLength(unseenMessages.amount);
                setUnseenMessages((prev) => [
                    ...prev,
                    ...unseenMessages.messages,
                ]);
            }
        });

        socketIO.on('setMessagesAsSeen', (messages) => {
            if (unseenMessages?.room_id === room?.roomId) {
                setUnseenLength(0);
                setUnseenMessages([]);
            }
        });
    }, []);

    useEffect(() => {
        if (currentRoom?.roomId === room?.roomId) {
            setSocketId(room.socketId);
        }
    }, [room]);

    useEffect(() => {
        if (defaultRoomId === room?.roomId) {
            selectRoom(true);
        }
    }, [room, defaultRoomId, currentRoom?.roomId]);

    function selectRoom(isDefault) {
        if (room.roomId === currentRoom?.roomId) {
            return;
        }

        if (!isDefault && !!defaultRoomId) {
            history.push('/chat');
        }

        setMessagesAsSeen(unseenMessages, socketId);
        setUnseenLength(0);
        pageRef.current = 1;

        !isDefault && setMessages(null);
        setSocketId(room.socketId);
        checkUserStatus(room.id);

        setCurrentRoom(room);
        setReciverConnection(connection);

        if (!!notifications?.length) {
            const notification = notifications.find((note) => {
                return note?.room_id === currentRoom?.roomId;
            });
            if (!!notification?._id) {
                deleteNotification(notification?._id);
            }
        }
    }

    return (
        <Wrapper onClick={() => selectRoom(false)}>
            <Card key={userId} isSelected={room.roomId === currentRoom?.roomId}>
                <ImageWrapper online={connection}>
                    <LazyImg
                        src={room?.profilePhoto}
                        alt={'imagen'}
                        borderRadius="50%"
                        width="50px"
                        height="50px"
                    />
                </ImageWrapper>
                <TextWrapper>
                    <Text ellipsis="ellipsis" fontSize="16px" fontWeight="600">
                        {toTitleCase(room?.name)}
                    </Text>
                    <LatestMessageWrapper>
                        {(!!recentMessage?.fileUrl ||
                            recentMessage?.file_url) &&
                            (MIMETYPE_CASES.includes(
                                recentMessage?.mimeType ||
                                    recentMessage?.mime_type
                            ) ? (
                                <CameraAlt fontSize="small" />
                            ) : (
                                <Description fontSize="small" />
                            ))}
                        <Text
                            ellipsis="ellipsis"
                            color="#616161"
                            fontSize="14px"
                            fontWeight="400"
                        >
                            {recentMessage?.message}
                        </Text>
                    </LatestMessageWrapper>
                </TextWrapper>
                <InfoWrapper>
                    {latestMessage?.createdAt && (
                        <Span color="#616161" fontSize="14px" fontWeight="500">
                            {timeSent}
                        </Span>
                    )}
                    {unseenLength > 0 && !!unseenLength && (
                        <Pending
                            color="#ffffff"
                            backgroundColor="#F8423F"
                            fontWeight="600"
                            fontSize="11px"
                        >
                            {unseenLength}
                        </Pending>
                    )}
                </InfoWrapper>
            </Card>
        </Wrapper>
    );
};

export default ChatRoomCard;

const Wrapper = styled.section`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 100%;
`;

const Card = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 85px;
    gap: 1rem;
    background-color: ${({ isSelected }) =>
        isSelected ? '#f1f1f1' : '#ffffff'};
    cursor: pointer;
    transition: ease-out all 0.1s;

    &:hover {
        transition: ease-in all 0.1s;
        background-color: #f5f5f5;
    }

    &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background-color: #e0e0e0;
        position: absolute;
        bottom: 0;
    }

    @media screen and (max-width: 1024px) {
        justify-content: center;
    }

    @media screen and (max-width: 768px) {
        justify-content: flex-start;
    }
`;

const TextWrapper = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.3rem;

    width: 150px;
    overflow: hidden;

    @media screen and (max-width: 1024px) {
        display: none;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        /* max-width: 300px; */
        display: block;
    }
`;

const InfoWrapper = styled.div`
    box-sizing: border-box;
    width: 70px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.4rem;

    @media screen and (max-width: 1024px) {
        display: none;
    }

    @media screen and (max-width: 768px) {
        display: flex;
        /* max-width: 50px; */
        width: 100%;
    }
`;

const Pending = styled(Span)`
    display: grid;
    place-items: center;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    padding: 3px;
`;

const ImageWrapper = styled.section`
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        top: -2px;
        right: -2px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #ffffff;
        z-index: 5;
    }

    &::after {
        content: '';
        position: absolute;
        top: 1.5px;
        right: 1px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: ${({ online }) => (online ? '#35d0a5' : '#A8A8A8')};
        z-index: 10;
    }
`;

const LatestMessageWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.3em;

    svg {
        width: 20px;
        height: 20px;
        fill: #a3a3a3;
    }
`;
