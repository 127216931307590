import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useTheme from '../../../hooks/useTheme';
import useLayout from '../../../hooks/useLayout';
import { checkRoles } from '../../../helpers/roles.helper';
import useGeneralSubjectAnnouncements from '../../../hooks/subject/useGeneralSubjectAnnouncements';
import { getNotificationService } from '../../../app/modules/Services/chat.services';
import { getNotificationFake } from '../../../redux/api/notifications';
import MenuTeacher from './MenuTeacher';
import MenuUser from '../navegation/MenuUser';
import Messages from '../navegation/Messages';
import Notifications from '../navegation/Notifications';
import AnnouncementsNotification from '../navegation/AnnouncementsNotification';
import GoBack from '../../../modules/acropolisCommon/components/GoBack';
import { MenuRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import MessagesV2 from '../navegation/chatV2/MessagesV2';

const NavegationTeacher = (props) => {
    const { toggleMenu, menu, closeMenu } = props;
    // REDUX
    const user = useSelector((state) => state.auth.user);
    const activeRol = useSelector((state) => state.auth.activeRol);

    const microlearnings = useSelector(
        (state) => state.microlearnings.microlearnings
    );
    const { secondary } = useTheme();

    // STATE
    const [messagesState, setMessagesState] = useState(false);
    const [notificationStateFake, setNotificationFake] = useState([]);
    const { menu: currentMenu } = useLayout();

    const { announcementsGeneralData, loading, error, resetGeneralData } =
        useGeneralSubjectAnnouncements(user.repo_id, activeRol);

    // EFFECTS
    useEffect(() => {
        if (notificationStateFake.length === 0) {
            getNotificacion();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (microlearnings === null) {
            getNotifications();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [microlearnings]);

    // FUNCTIONS
    const getNotifications = async () => {
        const notification = await getNotificationService(user.partner_id);
        if (Array.isArray(notification.result)) {
            setMessagesState(notification.result);
        }
    };

    const getNotificacion = async () => {
        const response = await getNotificationFake('docente');
        if (!response.error) {
            let result = response.sort((a, b) => b.id - a.id);
            setNotificationFake(result);
        } else {
            console.error(response.error);
        }
    };

    // Recargar Notificaciones
    const refreshNotification = () => {
        getNotificacion();
    };

    // RETURN
    return (
        <>
            <Header color={secondary}>
                <LogoWrapper>
                    <FakeIconButton onClick={toggleMenu}>
                        <MenuRounded color="secondary" />
                    </FakeIconButton>
                    <Link to="/campus">
                        <FakeImg
                            loading="lazy"
                            src="/assets/logo-aden.svg"
                            alt="logo"
                        />
                    </Link>
                </LogoWrapper>
                <MenuWrapper>
                    <GoBack title={currentMenu} />
                </MenuWrapper>
                <Nav>
                    {/** Dracmas */}
                    {/* <UserDracmas /> */}

                    {/* //Anuncios generales */}
                    <AnnouncementsNotification
                        announcements={announcementsGeneralData}
                        refreshAnnouncements={resetGeneralData}
                        loading={loading}
                        error={error}
                    />

                    <MessagesV2 />
                    {/* {checkRoles(['Admin', 'alumno']) && (
                    )} */}

                    {/* Mensajes */}
                    {/* {checkRoles(['Admin', 'alumno', 'visita']) && (
                        <Messages messages={messagesState} />
                    )} */}

                    {/* Notificaciones */}
                    <Notifications
                        notifications={notificationStateFake}
                        refreshNotification={refreshNotification}
                    />

                    {/** Menu del usuario */}
                    <MenuUser />
                </Nav>
            </Header>
            <MenuTeacher menu={menu} closeMenu={closeMenu}></MenuTeacher>
        </>
    );
};

export default NavegationTeacher;

const Header = styled.header`
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    left: 0;
    position: fixed;
    width: 100%;
    height: 70px;
    background-color: ${(p) => p.color};
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    z-index: 999;

    @media (max-width: 768px) {
        padding: 0 12px;
    }
`;

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin-right: 1.5rem;

    @media (max-width: 425px) {
        column-gap: 0.5rem;
        margin-right: 0;
    }
`;

const MenuWrapper = styled.div`
    width: 100%;
    overflow: hidden;

    @media (max-width: 425px) {
        column-gap: 0.5rem;
    }
`;

const FakeIconButton = styled(IconButton)`
    display: none;

    @media (max-width: 768px) {
        display: flex;
    }
`;

const FakeImg = styled.img`
    max-height: 46px;
`;

const Nav = styled.nav`
    display: flex;
    align-items: center;
    column-gap: 1rem;

    @media (max-width: 425px) {
        column-gap: 0.5rem;
    }
`;
