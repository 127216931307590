import styled from 'styled-components';

// Components
import CommonButton from '../../components/common/CommonButton';
import ResourceCard from './digitalLibrary/ResourceCard';
import APAStandars from './digitalLibrary/APAStandards';
import HeaderCard from './digitalLibrary/HeaderCard';

const DigitalLibrary = () => {
    return (
        <Container>
            <h1>¡Te damos la bienvenida a tu biblioteca digital!</h1>
            <span>
                En este espacio podrás acceder a una
                <b> amplia variedad de recursos: </b>
                bases de datos, libros, artículos, informes de investigación,
                tesis doctorales y mucho más. Además,{' '}
                <b>
                    podrás disfrutar del beneficio del préstamo digital de
                    libros de las editoriales más prestigiosas a nivel mundial.
                </b>
            </span>
            <HeaderCard />
            <span>
                A continuación, encontrarás <b>todos los recursos</b> necesarios
                para <b>impulsar tu crecimiento académico:</b>
            </span>
            <ResourceCard
                resourceName="EBSCO"
                resourceLink="https://search.ebscohost.com/login.aspx?authtype=ip,uid&amp;custid=ns249009&amp;groupid=main&amp;profile=ehost&amp;defaultdb=bth&amp;user=adeninc&amp;password=@den1nc"
                tutorialLabel="¿Cómo acceder a los EBSCO eBooks?"
                tutorialLink="https://www.youtube.com/embed/Pp-8_ZciU3g"
            />
            <ResourceCard
                resourceName="eLIBRO"
                resourceLink="https://www.aden.org/biblioteca/"
                tutorialLabel="¿Cómo descargar títulos con la nueva aplicación de eLIBRO paso a paso?"
                tutorialLink="https://www.youtube.com/embed/lxmNOX8erdA"
            />
            <APAStandars />
            <h2>Guía de aplicación de Normas APA</h2>
            <span>
                El objetivo de esta guía es que aprendas e incorpores sus
                indicaciones para aplicarlas al momento de realizar una
                comunicación académica.
            </span>
            <CommonButton
                label="Ver guía"
                variant="filled"
                onClick={() =>
                    window.open(
                        'https://drive.google.com/file/d/1_NGL7AlzW6mT1_39Cxp7qf7vRnIPXyqc/view'
                    )
                }
            />
            <h2>
                Guía de buenas prácticas para citas y referencias bibliográficas
            </h2>
            <span>
                Esta guía está diseñada para ayudarte a comprender y aplicar
                correctamente las normas de citación en tus trabajos académicos.
            </span>
            <CommonButton
                label="Ver guía"
                variant="filled"
                onClick={() =>
                    window.open(
                        'https://drive.google.com/file/d/1Pxk_VKBOuso4Yz4D2skDuLTCGftzSPpT/view'
                    )
                }
            />
        </Container>
    );
};

export default DigitalLibrary;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem 2rem;

    h1 {
        font-size: 20px;
        font-weight: 700;
    }

    h2 {
        font-size: 20px;
        font-weight: 700;
        color: #b31d15;
    }

    span {
        font-size: 18px;
        font-weight: 400;

        b {
            font-size: 18px;
            font-weight: 400;
        }
    }
`;
