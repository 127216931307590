import React, { useState } from 'react';
import styled from 'styled-components';

import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon,
    WhatsappShareButton,
    WhatsappIcon,
    XIcon,
} from 'react-share';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import { IconButton, Modal, Tooltip } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';
/* import { userAction } from '../../helpers/userActions.helper' */
import LazyImg from '../../components/common/LazyImg';
import TeamworkHighFive from '../../assets/media/aden/teamwork-high-five-1.png';
import { Text } from '../../components/common/Texts';
import CommonButton from '../../components/common/CommonButton';

const SharedAchivement = (props) => {
    const { modalIsOpen, handleCloseModal, link } = props;

    // STATE
    const [open, setOpen] = useState(false);

    // FUNCTIONS
    const handleTooltipOpen = () => {
        setOpen(true);
        handleSharedProfile('Link');
        setTimeout(() => {
            setOpen(false);
        }, 1000);
    };

    const handleSharedProfile = (type) => {
        /* userAction({
            object_id: 0,
            object: 'perfil',
            name: 'Compartió su perfil',
            activity: 'Compartir perfil',
            medio: type,
        }); */
    };

    return (
        <Modal open={modalIsOpen} onClose={handleCloseModal}>
            <SharedProfileContainer>
                {/** Close */}
                <CloseButtonWrapper>
                    <IconButton
                        onClick={handleCloseModal}
                        style={{
                            borderRadius: '10px',
                            margin: 0,
                            backgroundColor: '#f3f6f9',
                        }}
                        size="small"
                    >
                        <CloseRounded />
                    </IconButton>
                </CloseButtonWrapper>
                {/** Socials */}
                <LazyImg
                    src={TeamworkHighFive}
                    width="100%"
                    height="100%"
                    backgroundColor="transparent"
                />
                <Text
                    textAlign="center"
                    fontSize="24px"
                    fontWeight="600"
                    color="#222222"
                >
                    ¡Comparte tu logro <br /> con tus colegas!
                </Text>
                <SocialsWrapper>
                    <SocialsGrid>
                        <LinkedinShareButton
                            url={link}
                            onClick={() => handleSharedProfile('Linkedin')}
                        >
                            <LinkedinIcon round size={48} />
                        </LinkedinShareButton>
                        <FacebookShareButton
                            url={link}
                            onClick={() => handleSharedProfile('Facebook')}
                        >
                            <FacebookIcon round size={48} />
                        </FacebookShareButton>
                        <TwitterShareButton
                            url={link}
                            onClick={() => handleSharedProfile('Twitter')}
                        >
                            <XIcon round size={48} />
                        </TwitterShareButton>
                        <WhatsappShareButton
                            url={link}
                            onClick={() => handleSharedProfile('Whatsapp')}
                        >
                            <WhatsappIcon round size={48} />
                        </WhatsappShareButton>
                    </SocialsGrid>
                </SocialsWrapper>
                {/** Link */}
                <LinkWrapper>
                    <span>o copia el siguiente enlace:</span>
                    <LinkDiv>
                        <input disabled value={link} />
                        <CopyToClipboard text={link} onCopy={handleTooltipOpen}>
                            <Tooltip
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                open={open}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title="Copiado"
                                placement="top"
                            >
                                <CommonButton variant="filled" label="Copiar" />
                            </Tooltip>
                        </CopyToClipboard>
                    </LinkDiv>
                </LinkWrapper>
            </SharedProfileContainer>
            {/** Image */}
        </Modal>
    );
};

export default SharedAchivement;

const SharedProfileContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 3rem 5rem;
    width: max-content;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow:
        0px 3px 6px 0px #0000003b,
        0px 3px 6px 0px #00000029;

    @media (width < 768px) {
        padding: 3rem 2rem;
    }
`;

const CloseButtonWrapper = styled.div`
    position: absolute;
    top: 5%;
    right: 5%;
    z-index: 999;
`;

const SocialsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    text-align: center;
`;

const SocialsGrid = styled.div`
    display: flex;
    justify-content: center;
    column-gap: 1rem;
`;

const LinkWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 0.5rem;
    width: 100%;
`;

const LinkDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 8px;

    input {
        background-color: transparent;
        border: none;
        outline: none;
        color: #9e9ea7;
        width: 100%;
    }
`;
