import React, { useState } from 'react';
import styled from 'styled-components';

// Components
import BannerInternationalWorkshop from './BannerInternationalWorkshop';
/* import PhysicalEvents from "./PhysicalEvents"; */
import OnlineEvents from './components/OnlineEvents';
import Tabs from '../../components/common/Tabs';
import Graduation from './Graduation';

// Hooks
import { useQueryParams } from '../../hooks/useQueryParams';

// Helpers
import { titleHTML } from '../../helpers/title.helper';

const Events = () => {
    titleHTML('Talleres presenciales');

    const queryTab = useQueryParams();
    const initialTab =
        queryTab.get('tab') === 'physical-events'
            ? 1
            : queryTab.get('tab') === 'online-events'
              ? 0
              : queryTab.get('tab') === 'international-workshop'
                ? 2
                : 0;

    //STATES
    const [currentTab, setCurrentTab] = useState(initialTab);

    const OPTIONS = [
        {
            id: 0,
            label: 'Eventos online',
        },
        {
            id: 1,
            label: 'Eventos presenciales',
        },
        {
            id: 2,
            label: 'Workshops internacionales',
        },
    ];

    const compareDates = (date) => {
        const dateParts = date.split('-');
        const dateObject = new Date(
            dateParts[0],
            dateParts[1] - 1,
            dateParts[2]
        );
        dateObject.setHours(0, 0, 0, 0);

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        return dateObject < today;
    };

    const workshops = [
        {
            title: 'El impacto de la IA',
            description: '',
            country: 'Barcelona',
            date: '08 al 10 de Septiembre, 2025',
            id: 6,
            img: './assets/internationalWorkshop/innovacion.png',
            description_complete: '',
            link: 'https://cursos.aden.org/product/1250',
            untilDate: 'Viernes 29 de Agosto',
            briefing: '15 de agosto',
            disabled: compareDates('2025-08-30'),
            // location: 'C/ de Rosselló i Pòrcel, 7-11',
        },
        {
            title: 'LATAM meets Silicon Valley',
            description: '',
            country: 'Silicon Valley',
            date: '22 al 25 de Octubre, 2025',
            id: 4,
            img: './assets/internationalWorkshop/Workshop-SV.png',
            description_complete: '',
            link: 'https://cursos.aden.org/product/1252',
            untilDate: 'Miércoles 1 de Octubre',
            briefing: '22 de septiembre',
            disabled: compareDates('2025-10-01'),
            // location: 'Hotel Pullman Miami Airport',
        },
        {
            title: 'Innovación, Tecnología y Tendencias',
            description: '',
            country: 'Miami',
            date: '19 al 21 de Noviembre, 2025',
            id: 3,
            img: './assets/internationalWorkshop/nuevas-tecnologias.png',
            description_complete: '',
            link: 'https://cursos.aden.org/product/1251',
            untilDate: 'Lunes 3 de Noviembre',
            briefing: '18 de octubre',
            disabled: compareDates('2025-11-03'),
            // location: 'Hotel Pullman Miami Airport',
        },
    ];

    const ActiveTabContent = () => {
        if (currentTab === 0) {
            return <OnlineEvents type="online" />;
        }

        if (currentTab === 1) {
            return <OnlineEvents type="physical" />;
        }

        if (currentTab === 2) {
            return (
                <WrapperWorkshop>
                    <WrapperCard>
                        <BannerInternationalWorkshop />
                        <Container>
                            <Graduation workshops={workshops} />
                        </Container>
                    </WrapperCard>
                </WrapperWorkshop>
            );
        }
        return <div></div>;
    };

    return (
        <>
            <TabsWrapper>
                <Tabs
                    tabValue={currentTab}
                    handleTabValue={setCurrentTab}
                    tabOptions={OPTIONS}
                />
            </TabsWrapper>
            <Container style={{ marginTop: '50px' }}>
                <ActiveTabContent />
            </Container>
        </>
    );
};

export default Events;

const Container = styled.div`
    display: flex;
    overflow: hidden;
    border-radius: 20px;
    flex-direction: column;
    row-gap: 2rem;
`;

const TabsWrapper = styled.div`
    width: calc(100% - 6rem - 70px);
    position: fixed;
    left: 70px;
    top: 70px;
    z-index: 10;
    border-top: 1px solid #f7f7f7;
    padding: 0 3rem;
    background-color: #ffffff;
    @media (max-width: 768px) {
        left: 0;
        width: calc(100% - 6rem);
    }
`;

const WrapperWorkshop = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    gap: 3rem;
    height: 100%;
`;

const WrapperCard = styled.div`
    width: calc(100% - 48px);
    box-shadow:
        0px 12px 24px -15px #0000001a,
        0px 0px 10px -6px #00000040;
    border-radius: 20px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 48px;
    background-color: #fff;
`;
