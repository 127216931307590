import styled from 'styled-components';
import { useState } from 'react';

// Components
import FormEditProfile from '../../../../components/ui/molecules/academic/FormEditProfile';
import { SnackBarGenerico } from '../../../../app/campus/components/SnackBarGenerico';
import CommonButton from '../../../../components/common/CommonButton';

// Material UI
import CancelIcon from '@mui/icons-material/Cancel';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';

// Hooks
import { useSnackBar } from '../../../../app/campus/hooks/useSnackBar';

const FormWsGraduation = (props) => {
    const { ws, handleNewGetWs, isReserved, id, link, disabled } = props;

    const [isOpen, setIsOpen] = useState(false);

    const handleRedirect = () => {
        window.open(link);
    };

    // FUNCTIONS

    function handleCloseModal() {
        setIsOpen(false);
    }

    const [snackBar, closeSnackBar] = useSnackBar();

    return (
        <>
            <CommonButton
                disabled={ws === null || isReserved || disabled ? true : false}
                variant="filled"
                onClick={handleRedirect}
                label={
                    disabled
                        ? 'Inscripción finalizada'
                        : id === 4
                          ? 'Postularme'
                          : 'Inscribirme'
                }
            />
            <Modal open={isOpen} onClose={handleCloseModal}>
                <ContainerModal>
                    <ButtonFake onClick={handleCloseModal}>
                        <CancelIcon color="primary" />
                    </ButtonFake>
                    <FormEditProfile
                        id={id}
                        ws={ws}
                        handleNewGetWs={handleNewGetWs}
                    />
                </ContainerModal>
            </Modal>
            <SnackBarGenerico
                show={snackBar.show}
                handleClose={closeSnackBar}
                message={snackBar.message}
                variant={snackBar.variant}
            />
        </>
    );
};

export default FormWsGraduation;

const ContainerModal = styled.div`
    border: none;
    outline: none;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    width: 1200px;
    max-width: 90vw;
    height: 90vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    @media (max-width: 768px) {
        max-width: 100vw;
        width: 100%;
        height: 100%;
        border-radius: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: none;
    }
`;

const ButtonFake = styled(Button)`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
`;
