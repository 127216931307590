import { useEffect, useState } from 'react';
import styled from 'styled-components';

// Components
import FontAwesomeIcon from '../../components/common/FontAwesomeIcon';
import SimpleLoading from '../../components/common/SimpleLoading';
import CommonButton from '../../components/common/CommonButton';

// Libraries
import { useParams } from 'react-router-dom';
import html2canvas from 'html2canvas';
import parse from 'html-react-parser';
import { jsPDF } from 'jspdf';

// API
import { getAchievement } from './api/getAchievement';
import SharedAchivement from './SharedAchivement';

const Achievement = () => {
    const { repo_id, id } = useParams();

    const [achievementHTML, setAchievementHTML] = useState(null);
    const [openShared, setOpenShared] = useState(false);

    const fetchAchievement = async () => {
        // Llamada a la API para obtener el certificado
        const response = await getAchievement(repo_id, id);
        setAchievementHTML(response);
    };

    useEffect(() => {
        fetchAchievement();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const generatePDF = async () => {
        const element = document.getElementById('toPdf');

        html2canvas(element, {
            useCORS: true, // Permite cargar imágenes externas
            allowTaint: true,
            scale: 3, // Aumenta la calidad
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/PNG');
            const pdf = new jsPDF('l', 'mm', 'a4'); // A4 horizontal

            const imgWidth = 297; // Ancho A4 en mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width; // Mantener proporción

            pdf.addImage(
                imgData,
                'PNG',
                0,
                0,
                imgWidth,
                imgHeight,
                undefined,
                'FAST'
            );
            pdf.save('certificado.pdf');
        });
    };

    const handleDownloadImage = () => {
        const element = document.getElementById('toPdf');

        html2canvas(element, {
            useCORS: true,
            scale: 3,
        }).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            link.download = 'certificado.png';
            link.click();
        });
    };

    return (
        <Container>
            <SharedAchivement
                modalIsOpen={openShared}
                handleCloseModal={() => setOpenShared(false)}
                link={`${window.location.origin}/logros/${repo_id}/${id}`}
            />
            <Aside>
                <h2>{achievementHTML?.title}</h2>
                <p>{achievementHTML?.description}</p>
                <p>
                    ID de la credencial: <b>{achievementHTML?.id}</b>
                </p>
                <h4>OPCIONES</h4>
                <CommonButton
                    onClick={generatePDF}
                    label="Descargar como PDF"
                    variant="filled"
                    Icon={() => (
                        <div>
                            <FontAwesomeIcon
                                icon="fa-solid fa-file-pdf"
                                color="#fff"
                                size={16}
                            />
                        </div>
                    )}
                />
                <CommonButton
                    onClick={handleDownloadImage}
                    label="Descargar como Imagen"
                    variant="filled"
                    Icon={() => (
                        <div>
                            <FontAwesomeIcon
                                icon="fa-solid fa-image"
                                color="#fff"
                                size={16}
                            />
                        </div>
                    )}
                />
                <CommonButton
                    onClick={() => setOpenShared(true)}
                    label="Compartir"
                    variant="filled"
                    Icon={() => (
                        <div>
                            <FontAwesomeIcon
                                icon="fa-solid fa-link"
                                color="#fff"
                                size={16}
                            />
                        </div>
                    )}
                />

                <section>
                    <div>
                        <h5>EXPEDIDO EL</h5>
                        <p>{achievementHTML?.generation_date}</p>
                    </div>
                    <div>
                        <h5>EXPIRA EL</h5>
                        <p
                            style={{
                                color: achievementHTML?.expiration_date
                                    ? 'black'
                                    : '#A8A8A8',
                            }}
                        >
                            {achievementHTML?.expiration_date || 'No caduca'}
                        </p>
                    </div>
                </section>
            </Aside>
            {!achievementHTML ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '70vw',
                    }}
                >
                    <SimpleLoading />
                </div>
            ) : (
                <div id="toPdf">{parse(achievementHTML?.html)}</div>
            )}
        </Container>
    );
};

export default Achievement;

const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    justify-content: space-between;
    overflow-y: auto;

    #toPdf {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70vw;
        .certificadocontainer {
            img:first-of-type {
                margin-bottom: -2rem;
            }
            img:nth-of-type(2) {
                margin-top: -10rem;
            }
            img:nth-of-type(4) {
                margin-top: -6rem;
            }
        }
    }
`;

const Aside = styled.span`
    width: calc(30vw - 4rem);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    section {
        display: flex;
        gap: 2rem;
        margin-top: 1rem;
        div {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
        }
    }
`;
