import React, { useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';

// Components
import RegisterEventModal from '../../../events/components/modals/RegisterEventModal';
import FontAwesomeIcon from '../../../../components/common/FontAwesomeIcon';
import { Text } from '../../../../components/common/Texts';

// Material UI
import { ScheduleRounded, Check } from '@mui/icons-material';
import { Card } from '@mui/material';

// Redux
import { useSelector } from 'react-redux';

const EventsCard = (props) => {
    const { event, countryName, getDynamicEvent, tabsFake } = props;

    const colorPrimary = useSelector(
        (state) => state?.ondemand?.ondemand?.primary_color
    );

    // STATES
    const [openModal, setOpenModal] = useState(false);

    moment.updateLocale('es', {
        months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
            '_'
        ),
        monthsShort:
            'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split(
                '_'
            ),
        weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split(
            '_'
        ),
        weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
        weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
    });

    const currentDate = new Date();
    const formatCurrentDate = moment(currentDate).locale('es').format('LL');
    const formatDateBegin = moment(event?.date_begin).locale('es').format('LL');

    const getLocalUTC = () => {
        const hours = new Date().getTimezoneOffset();
        if (hours > 0) {
            return `-${hours / 60}`;
        } else {
            return `+${(hours * -1) / 60}`;
        }
    };

    const utc = getLocalUTC();

    const eventDateBegin = new Date(
        event.date_begin.replaceAll('-', '/') + `${utc} UTC`
    );

    const monthNames = [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre',
    ];

    // CAMBIA LA HORA SEGÚN LA LOCALIZACIÓN DEL USUARIO
    const ToggleLocationHour = (props) => {
        const { toggler } = props;
        const cases = {
            Argentina: (
                <Text fontSize="11px" color="#616161" component="span">
                    {event.hour_begin}
                    {' (AR) '}{' '}
                </Text>
            ),
            Panama: (
                <Text fontSize="11px" color="#616161" component="span">
                    {getHourNumber(event.hour_begin) -
                        2 +
                        ':' +
                        getMinuteNumber(event.hour_begin)}{' '}
                    {' (PA) '}{' '}
                </Text>
            ),
            'Costa Rica': (
                <Text fontSize="11px" color="#616161" component="span">
                    {getHourNumber(event.hour_begin) -
                        3 +
                        ':' +
                        getMinuteNumber(event.hour_begin)}{' '}
                    {' (CR) '}
                </Text>
            ),
        };

        return cases[toggler] || cases['Argentina'];
    };

    const formatEventDateBegin = moment(eventDateBegin)
        .locale('es')
        .format('LL');

    const getHourNumber = (hour) => {
        return parseInt(hour.substring(0, 2));
    };

    const getMinuteNumber = (hour) => {
        return hour.substring(3, 5);
    };

    const handleClick = () => {
        setOpenModal(!openModal);
    };

    return (
        <>
            {openModal && (
                <RegisterEventModal
                    event={event}
                    handleClick={handleClick}
                    getDynamicEvent={getDynamicEvent}
                    tabsFake={tabsFake}
                />
            )}
            <CardFake
                isRegistered={event.is_registered}
                colorPrimary={colorPrimary}
                type={event.modality}
                onClick={handleClick}
            >
                <Body color="#fff" type={event.modality}>
                    {event.modality === 'physical' && (
                        <>
                            <Footer>
                                <DateWrapper isRegistered={event.is_registered}>
                                    <FontAwesomeIcon
                                        icon="fa-regular fa-calendar-day"
                                        size={16}
                                        color={'#fff'}
                                    />
                                    <p>
                                        {eventDateBegin.getDate()} de{' '}
                                        {monthNames[eventDateBegin.getMonth()]}
                                    </p>
                                </DateWrapper>
                                <WrapperInscription
                                    colorPrimary={colorPrimary}
                                    type={event.is_registered}
                                >
                                    {event.is_registered ? (
                                        <>
                                            <FontAwesomeIcon
                                                icon="fa-regular fa-check"
                                                size={20}
                                            />{' '}
                                            Inscripto
                                        </>
                                    ) : (
                                        <>
                                            <FontAwesomeIcon
                                                icon="fa-regular fa-plus"
                                                size={20}
                                            />{' '}
                                            Inscribirme
                                        </>
                                    )}
                                </WrapperInscription>
                            </Footer>
                        </>
                    )}
                    {event.modality === 'online' &&
                        ((eventDateBegin === currentDate ||
                            formatEventDateBegin === formatCurrentDate) &&
                        event.is_registered ? (
                            <Footer>
                                <DateWrapper isRegistered={event.is_registered}>
                                    <FontAwesomeIcon
                                        icon="fa-regular fa-calendar-day"
                                        size={16}
                                        color={'#fff'}
                                    />
                                    <p>
                                        {eventDateBegin.getDate()} de{' '}
                                        {monthNames[eventDateBegin.getMonth()]}
                                    </p>
                                </DateWrapper>
                                <WrapperText>
                                    <CircleLive />
                                    <Text
                                        fontSize="11px"
                                        color="#b31d15"
                                        fontWeight="bold"
                                        component="span"
                                    >
                                        EN VIVO HOY
                                    </Text>
                                </WrapperText>
                            </Footer>
                        ) : event.is_registered ? (
                            <Footer>
                                <DateWrapper isRegistered={event.is_registered}>
                                    <FontAwesomeIcon
                                        icon="fa-regular fa-calendar-day"
                                        size={16}
                                        color={'#fff'}
                                    />
                                    <p>
                                        {eventDateBegin.getDate()} de{' '}
                                        {monthNames[eventDateBegin.getMonth()]}
                                    </p>
                                </DateWrapper>
                                <WrapperInscription
                                    colorPrimary={colorPrimary}
                                    type={true}
                                >
                                    <FontAwesomeIcon
                                        icon="fa-regular fa-check"
                                        size={20}
                                    />{' '}
                                    Inscripto
                                </WrapperInscription>
                            </Footer>
                        ) : (
                            <Footer>
                                <DateWrapper isRegistered={event.is_registered}>
                                    <FontAwesomeIcon
                                        icon="fa-regular fa-calendar-day"
                                        size={16}
                                        color={'#fff'}
                                    />
                                    <p>
                                        {eventDateBegin.getDate()} de{' '}
                                        {monthNames[eventDateBegin.getMonth()]}
                                    </p>
                                </DateWrapper>
                                <WrapperInscription
                                    colorPrimary={colorPrimary}
                                    type={false}
                                >
                                    <FontAwesomeIcon
                                        icon="fa-regular fa-plus"
                                        size={20}
                                    />{' '}
                                    Inscribirme
                                </WrapperInscription>
                            </Footer>
                        ))}
                    <TextFakeWrapper>
                        <TextFake
                            id="title"
                            fontSize="14px"
                            fontWeight="bold"
                            color="#222222"
                        >
                            {event.publication_name}
                        </TextFake>
                    </TextFakeWrapper>
                    <WrapperText>
                        <ScheduleRounded />
                        <ToggleLocationHour toggler={'Argentina'} />
                        {'|'}
                        <ToggleLocationHour toggler={'Panama'} />
                        {'|'}
                        <ToggleLocationHour toggler={'Costa Rica'} />
                    </WrapperText>
                    {event.modality === 'physical' && (
                        <WrapperText>
                            <FontAwesomeIcon
                                icon="fa-light fa-location-dot"
                                size={16}
                            />
                            <Text
                                fontSize="12px"
                                color={'#616161'}
                                component="span"
                            >
                                {event?.location?.country_name}
                                {event?.location?.city
                                    ? `, ${event.location.city}`
                                    : ''}
                                {event?.location?.street
                                    ? `, ${event.location.street}`
                                    : ''}
                            </Text>
                        </WrapperText>
                    )}
                </Body>
            </CardFake>
        </>
    );
};

export default EventsCard;

const CardFake = styled(Card)`
    box-sizing: border-box;
    display: inline-flex;
    position: relative;
    align-items: center;
    gap: 2rem;
    min-height: ${(p) => (p.type === 'online' ? '142px' : '142px')};
    cursor: pointer;
    width: 100%;
    border-radius: 10px;
    box-shadow:
        0px 6px 12px -6px rgba(24, 39, 75, 0.12),
        0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    scale: ${(p) => p.scale};
    border: 1px solid #dcdcdc;

    :before {
        content: '';
        width: 8px;
        background-color: #dcdcdc;
        position: absolute;
        height: 100%;
        left: 0;
        z-index: 1;
    }

    :hover {
        :before {
            background-color: ${(p) =>
                !!p.isRegistered
                    ? '#35d0a5'
                    : p.colorPrimary
                      ? p.colorPrimary
                      : '#b31d15'};
        }
    }
`;

const TextFakeWrapper = styled.div`
    height: auto;
    width: 100%;
`;

const TextFake = styled(Text)`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const Body = styled.div`
    display: inline-flex;
    flex-direction: column;
    padding: 1rem;
    gap: ${({ type }) => (type === 'physical' ? '.7rem' : '1.6rem')};
    position: relative;
    width: calc(100% - 25px);
    max-width: calc(100% - 25px);
    background: white;
    align-items: ${(p) => (p.type === 'default' ? 'center' : 'flex-start')};
    transition: ease-in-out 0.3s all;
`;

const WrapperText = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;

    svg {
        font-size: 16px;
        color: #a8a8a8;
    }
`;

const WrapperInscription = styled.div`
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 6px;
    color: ${(p) =>
        p.type ? '#28A986' : !!p.colorPrimary ? p.colorPrimary : '#b31d15'};
    font-size: 13px;
    padding: 0.2rem;
    font-weight: 700;
    svg {
        font-size: 1.1rem;
    }
`;

const CircleLive = styled.div`
    min-width: 12px;
    min-height: 12px;
    background-color: #b31d15;
    border-radius: 50%;
`;

const Footer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
`;

const DateWrapper = styled.div`
    display: flex;
    gap: 0.3rem;
    background-color: ${(p) => (p.isRegistered ? '#28A986' : '#B31D15')};
    padding: 8px 14px 8px 10px;
    border-radius: 100px;

    p {
        color: #fff;
        font-weight: 700;
        font-size: 13px;
    }
`;
