import * as types from '../types';
import { call, put, takeEvery } from 'redux-saga/effects';
import { getAchievements } from '../api/achievements';
import { achievementsActions } from '../actions';

function* achievementsRequest(action) {
    try {
        const res = yield call(getAchievements, action.payload);
        yield put(
            achievementsActions.getAchievementsSuccess(
                res.data.response_data.response
            )
        );
    } catch (error) {
        yield put(achievementsActions.getAchievementsFail(error));
    }
}

function* achievementsWatcher() {
    yield takeEvery(types.GET_ACHIEVEMENTS_REQUEST, achievementsRequest);
}

export { achievementsWatcher };
