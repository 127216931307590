import axios from 'axios';

export async function postSaveIpLogin(body, repoId) {
    const URL = `${process.env.REACT_APP_REPO}/v1/repo_aden/students/${repoId}/update_metadata`;
    try {
        const res = await axios.post(URL, body);
        return res;
    } catch (error) {
        throw new Error(error);
    }
}
