import styled from 'styled-components';

const APAStandars = () => {
    const cards = [
        {
            id: 1,
            label: 'Qué son las normas APA.',
            link: 'https://vimeo.com/302514797',
        },
        {
            id: 2,
            label: 'Cómo citar en textos cortos.',
            link: 'https://vimeo.com/302514515',
        },
        {
            id: 3,
            label: 'Cómo citar textos largos.',
            link: 'https://vimeo.com/302514228',
        },
        {
            id: 4,
            label: 'Qué es una lista de Referencia',
            link: 'https://vimeo.com/302862608',
        },
    ];

    return (
        <Container>
            <h2>
                Normas APA y recomendaciones para aprovechar al máximo tu
                Biblioteca Digital ADEN.
            </h2>
            <CardsContainer>
                {cards.map((card) => {
                    return (
                        <Card href={card.link} target="_blank" key={card.id}>
                            <label>
                                <b>Cápsula {card.id}</b> - {card.label}
                            </label>
                            <b>
                                <i>Biblioteca digital</i>
                            </b>
                        </Card>
                    );
                })}
            </CardsContainer>
        </Container>
    );
};

export default APAStandars;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;

    h2 {
        font-size: 20px;
        font-weight: 700;
        color: #b31d15;
    }
`;

const CardsContainer = styled.div`
    display: flex;
    justify-content: space-between;

    @media (width < 768px) {
        width: calc(100vw - 7rem);
        overflow-y: auto;
        gap: 1rem;
    }
`;

const Card = styled.a`
    cursor: pointer;
    height: 150px;
    width: calc(25% - 1rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-color: #f9f9f9;
    border: 1px solid #a8a8a8;
    border-radius: 20px;
    text-align: center;
    color: #000;

    label {
        cursor: pointer;
        max-width: 70%;
    }

    @media (width < 768px) {
        min-width: 140px;
        width: 140px;
    }
`;
