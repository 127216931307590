import React from 'react';
import styled from 'styled-components';

const Tabs = (props) => {
    const { tabValue, handleTabValue, tabOptions } = props;

    // RETURN
    return (
        <Container>
            {tabOptions.map((option) => (
                <Tab
                    onClick={() => handleTabValue(option.id)}
                    tabValue={tabValue}
                    key={option.id}
                    active={tabValue === option.id}
                >
                    {option.label}
                </Tab>
            ))}
        </Container>
    );
};

export default Tabs;

const Container = styled.div`
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (width < 768px) {
        overflow-x: auto;
        overflow-y: hidden;
        min-height: 70px;
        justify-content: start;
        width: 100%;
    }
`;

const Tab = styled.button`
    position: relative;
    padding: 1rem;
    cursor: pointer;
    color: ${(props) => (props.active ? '#222' : '#A8A8A8')};
    font-size: 16px;
    font-weight: ${(props) => (props.active ? '700' : '400')};
    transition: color 0.3s;
    background-color: #fff;

    &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 50%;
        transform: translateX(-50%);
        width: 70%;
        border-radius: 10px;
        height: 2px;
        background-color: ${(props) =>
            props.active ? '#b3261e' : 'transparent'};
    }

    @media screen and (max-width: 768px) {
        font-size: 14px;
        padding: 0.5rem 1rem;
    }
`;
