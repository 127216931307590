import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';

const TablePaginationGeneric = (props) => {
    const {
        totalItems,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
    } = props;

    return (
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalItems}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Filas por página:"
        />
    );
};

export default TablePaginationGeneric;
