import React, {
    forwardRef,
    useContext,
    useEffect,
    useState,
    useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
    ArrowBack,
    AttachFile,
    MoreVert,
    Send,
    SentimentSatisfiedAltSharp,
} from '@mui/icons-material';
import EmojiPicker from 'emoji-picker-react';
import { Text } from '../../../../components/common/Texts';
import LazyImg from '../../../../components/common/LazyImg';
import MessageInput from '../common/MessageInput';
import { Button, CircularProgress, IconButton } from '@mui/material';
import MessageBoard from './MessageBoard';
import { ChatContext } from '../../../../contexts/chat/ChatProvider';
import { socketIO } from '../../../../helpers/connectionSocket.helper';
import UnselectedView from './UnselectedView';
import { uploadFile } from '../../../../redux/api/chat-V2/http/upload';
import { createMessage } from '../../services/sockets/message';
import SkeletonLoading from '../../../../components/common/SkeletonLoading';
import Messages from '../../../../components/shared/navegation/Messages';
import { toTitleCase } from '../../../../helpers/titleCase.helper';
import useWindowWidth from '../../../../hooks/useWindowWidth';
import { getMessages } from '../../../../redux/api/chat-V2/http/message';

const ChatRoom = (props, ref) => {
    const {
        user,
        currentRoom,
        socketId,
        setRoomMessages,
        setLatestMessage,
        setMessages,
        messages,
        reciverConnection,
        setCurrentRoom,
        pageRef,
    } = useContext(ChatContext);

    const windowWidth = useWindowWidth();
    const history = useHistory();

    const bodyRef = useRef(null);
    const topRef = useRef(0);
    const scrollHeightRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [loadingFile, setLoadingFile] = useState(false);
    const [messageText, setMessageText] = useState('');
    const [openPicker, setOpenPicker] = useState(false);
    const [file, setFile] = useState(null);
    const [disablePagination, setDisablePagination] = useState(true);

    useEffect(() => {
        if (pageRef.current === 1) {
            !!currentRoom && gotoBottom('instant');
        }

        if (pageRef.current > 1) {
            bodyRef?.current?.scrollTo({
                behavior: 'instant',
                top: bodyRef?.current?.scrollHeight - scrollHeightRef.current,
            });
        }
    }, [currentRoom, messages?.length, pageRef.current]);

    useEffect(() => {
        !!currentRoom &&
            getRoomMessages({
                roomId: currentRoom?.roomId,
                size: 16,
                page: 0,
            });
    }, [currentRoom?.id]);

    useEffect(() => {
        if (!!bodyRef?.current) {
            bodyRef?.current?.addEventListener('scroll', () => {
                if (bodyRef?.current?.scrollTop === topRef.current) {
                    scrollHeightRef.current = bodyRef?.current?.scrollHeight;
                    getMessagesPage();
                }
            });
        }

        return () => {
            bodyRef?.current?.removeEventListener('scroll', () => {
                if (bodyRef?.current?.scrollTop === topRef.current) {
                    scrollHeightRef.current = bodyRef?.current?.scrollHeight;
                    getMessagesPage();
                }
            });
        };
    }, [bodyRef?.current, topRef.current, disablePagination]);

    useEffect(() => {
        socketIO.on('message', (message) => {
            if (currentRoom?.roomId === message?.room_id) {
                setMessages((prev) => [...new Set([...prev, message])]);
                setLatestMessage(message);
            }
        });
    }, [currentRoom?.roomId, messages]);

    const getRoomMessages = async ({ roomId, size = 16, offset = 0 }) => {
        setLoadingMessages(true);

        const response = await getMessages(roomId, size, offset);

        if (response.statusCode === 404 && pageRef.current > 1) {
            setLoadingMessages(false);
            setDisablePagination(false);
            topRef.current = 1;
            return;
        }

        if (response.statusCode === 404) {
            setLoadingMessages(false);
            setMessages(true);
            topRef.current = 1;
            return;
        }

        if (pageRef.current > 1) {
            // setTimeout(() => {
            setMessages((prev) => [...response, ...prev]);
            setLoadingMessages(false);
            // }, 3000);
            return;
        }

        setMessages(response);
        setRoomMessages(response);
        setLoadingMessages(false);
        setDisablePagination(true);
        topRef.current = 0;
    };

    const getMessagesPage = () => {
        if (!!disablePagination || !!loadingMessages) {
            return;
        }

        pageRef.current += 1;
        getRoomMessages({
            roomId: currentRoom?.roomId,
            size: 16,
            offset: 16 * pageRef.current,
        });
    };

    const sendMessage = async (e) => {
        socketIO.connect();
        e.preventDefault();
        setLoading(true);

        if (!messageText.length && !file) {
            return;
        }

        const body = {
            room_id: currentRoom.roomId,
            emitter_id: user.id,
            reciver_id: currentRoom.id,
            socket_id: socketId,
            message_body: { message: messageText },
            created_at: new Date(),
        };

        if (!!file) {
            setLoadingFile(true);
            const formData = new FormData();
            formData.append('file', file);

            setMessageText('');
            setOpenPicker(false);
            setFile(null);
            setMessages((prev) => [...prev, body]);
            setLatestMessage(body);

            const response = await uploadFile(formData);

            body.message_body = {
                message: messageText,
                file_url: response.file_url,
                download_name: response.download_name,
                mime_type: file.type,
                file_size: file.size,
                file_name: file.name,
            };
            createMessage(body);
            setLoadingFile(false);
            setLoading(false);
            return;
        }

        setMessages((prev) => (Array.isArray(prev) ? [...prev, body] : [body]));
        setLatestMessage(body);
        createMessage(body, setLoading);

        setMessageText('');
        setOpenPicker(false);
        setFile(null);
        setLoading(false);
    };

    const handleInputChange = (e) => {
        if (!!e.emoji) {
            setMessageText((prev) => prev + e.emoji);
            document.getElementById('chat-input').focus();
            return;
        }

        setMessageText(e.target.value);
    };

    const handleFileInput = (e) => {
        setFile(e.target.files[0]);
    };

    const gotoBottom = (behavior = 'instant') => {
        const chatBody = document.getElementById('chat-body');
        chatBody?.scrollTo({
            behavior: behavior,
            top: chatBody?.scrollHeight - chatBody?.clientHeight,
        });
    };

    const handlePicker = (event) => {
        event.type === 'click' && setOpenPicker(!openPicker);

        if (event.key === 'Escape') {
            setOpenPicker(false);
        }
    };

    const resetFileInput = () => {
        setFile(null);
    };

    const handleScrollEvent = () => {
        setDisablePagination(false);
    };

    if (!currentRoom) {
        return (
            <>
                {windowWidth > 780 && (
                    <Wrapper ref={ref}>
                        <UnselectedView />
                    </Wrapper>
                )}
            </>
        );
    }

    if (!messages) {
        return (
            <Wrapper ref={ref}>
                <SkeletonLoading height="100%" />
            </Wrapper>
        );
    }

    return (
        <Wrapper ref={ref}>
            <Header>
                <InfoWrapper>
                    <Image
                        src={currentRoom?.profilePhoto}
                        width="75px"
                        height="75px"
                        borderRadius="50%"
                        center={true}
                    />
                    <TextWrapper>
                        <Name color="#222222" fontSize="20px" fontWeight="700">
                            {toTitleCase(currentRoom?.name)}
                        </Name>
                        <Status
                            color="#222222"
                            fontSize="14px"
                            fontWeight="600"
                            status={reciverConnection}
                        >
                            {reciverConnection ? 'En línea' : 'Desconectado'}
                        </Status>
                    </TextWrapper>
                    {/* <MoreVert /> */}
                </InfoWrapper>
                {windowWidth < 780 && (
                    <IconButton
                        onClick={() => {
                            setCurrentRoom(null);
                            history.push('/chat');
                        }}
                    >
                        <ArrowBack />
                    </IconButton>
                )}
            </Header>
            <Body ref={bodyRef} onScroll={handleScrollEvent} id="chat-body">
                {loadingMessages && <SpinnerLoader />}
                <MessageBoard
                    messages={messages}
                    user={user}
                    loading={loading}
                    loadingFile={loadingFile}
                />
            </Body>
            <Form onSubmit={sendMessage}>
                <Footer isFileSelected={!!file}>
                    <EmojiPickerContainer onKeyDown={handlePicker}>
                        <IconButton onClick={handlePicker}>
                            <SentimentSatisfiedAltSharp />
                        </IconButton>
                        {openPicker && (
                            <CustomEmojiPicker
                                onEmojiClick={handleInputChange}
                            />
                        )}
                    </EmojiPickerContainer>
                    <CustomIconButton>
                        <FileInput
                            disabled={loading}
                            onChange={handleFileInput}
                            id="file-input"
                            name="file"
                            type="file"
                        />
                        <AttachFile />
                    </CustomIconButton>
                    <MessageInput
                        onChange={handleInputChange}
                        value={messageText}
                        file={file}
                        resetFileInput={resetFileInput}
                        loading={loading}
                    />
                    <SendButton
                        type="submit"
                        variant="contained"
                        disabled={loading || (!messageText?.length && !file)}
                    >
                        <Send fontSize="medium" />
                    </SendButton>
                </Footer>
            </Form>
        </Wrapper>
    );
};

export default forwardRef(ChatRoom);

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffffff;
    height: 100%;
    border-radius: 20px;
    padding: 1rem 2rem;
    box-shadow:
        0px 0px 10px -6px rgba(0, 0, 0, 0.25),
        0px 12px 24px -15px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 768px) {
        padding: 0 0 1rem 0;
    }
`;

const Header = styled.section`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: auto;
    height: 90px;
    padding: 1rem;

    &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background-color: #a8a8a8;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
    }

    @media screen and (max-width: 768px) {
        height: 60px;
    }
`;

const InfoWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
        img {
            width: 55px !important;
            height: 55px !important;
        }
    }
`;

const Image = styled(LazyImg)`
    display: grid;
    place-items: center;
`;

const TextWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    padding-left: 2rem;
`;

const Name = styled(Text)`
    @media screen and (max-width: 768px) {
        font-size: 17px;
    }
`;

const Status = styled(Text)`
    &::before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        background-color: ${({ status }) => (status ? '#35d0a5' : '#A8A8A8')};
        border-radius: 50%;
        margin-right: 0.5rem;

        @media screen and (max-width: 768px) {
            width: 10px;
            height: 10px;
        }
    }

    @media screen and (max-width: 768px) {
        font-size: 12px;
    }
`;

const Body = styled.section`
    position: relative;
    /* overflow-y: hidden; */
    overflow-y: auto;
    /* &:hover {
    } */
    max-height: 100%;
    flex: 1;
    scroll-behavior: auto;
`;

const SpinnerLoader = styled(CircularProgress)`
    position: absolute;
    margin: auto;
    top: 1rem;
    right: 0;
    left: 0;
`;

const Form = styled.form``;

const Footer = styled.section`
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    min-height: 100px;
    max-height: 500px;
    padding-top: ${({ isFileSelected }) => (isFileSelected ? '1rem' : 'none')};

    /* button > svg {
        background-color: red;
    } */

    &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background-color: #a8a8a8;
        position: absolute;
        top: 0;
    }

    @media screen and (max-width: 768px) {
        gap: 0.5rem;
    }
`;

const SendButton = styled(Button)`
    border-radius: 50% !important;
    width: 50px !important;
    height: 50px !important;
    margin-bottom: 0.6rem;

    &.MuiButton-root {
        padding: 1rem;
        min-width: 0 !important;
    }

    @media screen and (max-width: 768px) {
        &.MuiButton-root {
            padding: 1rem 2rem;
            min-width: 0 !important;
        }
    }
`;

const CustomIconButton = styled(IconButton)`
    position: relative;
    margin-bottom: 1rem;

    input {
        position: absolute;
        opacity: 0;
        height: 40px;
        width: 40px;
        cursor: pointer;
    }

    svg {
        cursor: pointer;
    }
`;

const FileInput = styled.input`
    cursor: pointer !important;
`;

const EmojiPickerContainer = styled.div`
    position: relative;
    margin-bottom: 1rem;
`;

const CustomEmojiPicker = styled(EmojiPicker)`
    position: absolute !important;
    margin: auto !important;
    top: -29rem !important;
    z-index: 20;
`;
