import styled from 'styled-components';
import FontAwesomeIcon from '../../../components/common/FontAwesomeIcon';
import { useSelector } from 'react-redux';

const HeaderCard = () => {
    return (
        <Container>
            <Photo
                src={
                    'https://www.aden.org/hallv2/assets/img/monicamelendez_bib.png'
                }
            />
            <Information>
                <JobTitle>BIBLIOTECARIA UNIVERSITARIA</JobTitle>
                <span />
                <Name>Mónica Melendez</Name>
                <label>Máster en documentación digital</label>
                <label>Contacto</label>
                <Contact>
                    <FontAwesomeIcon
                        icon="fa-light fa-envelope"
                        size={24}
                        color={'#b31d15'}
                    />
                    <p>mmelendez@adenuniversity.us</p>
                </Contact>
                <Contact>
                    <FontAwesomeIcon
                        icon="fa-brands fa-whatsapp"
                        size={24}
                        color={'#b31d15'}
                    />
                    <p>+52 252 4567897</p>
                </Contact>
            </Information>
        </Container>
    );
};

export default HeaderCard;

const Container = styled.div`
    display: flex;
    justify-content: start;
    align-items: start;
    gap: 2.5rem;
    width: calc(100% - 4rem);
    height: 250px;
    background-color: #f9f9f9;
    border: 1px solid #a8a8a8;
    border-radius: 20px;
    padding: 2.5rem 2rem;

    @media (width < 768px) {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        height: auto;
    }
`;

const Photo = styled.img`
    min-width: 260px;
    min-height: 260px;
    background-color: #000;
    border-radius: 100%;
`;

const Information = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 1rem;

    span {
        width: 100%;
        height: 2px;
        background-color: #a8a8a8;
    }

    label {
        font-size: 17px;
        font-weight: 700;
        color: #616161;
    }
`;

const JobTitle = styled.div`
    font-size: 16px;
    font-weight: 700;
    color: #616161;
`;

const Name = styled.div`
    font-size: 24px;
    font-weight: 700;
    color: #b31d15;
`;

const Contact = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 1rem;

    p {
        color: #666;
        text-decoration: underline;
    }
`;
