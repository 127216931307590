import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import GridResource from '../../components/common/GridResource';
import MicrolearningsCard from '../../components/ui/molecules/microlearnings/MicrolearningsCard';
import { useDispatch, useSelector } from 'react-redux';
import { microlearningsActions } from '../../redux/actions';
import SharedResource from '../../components/common/SharedResource';
// import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import MicrocontentCardSkeleton from '../../components/common/MicrocontentCardSkeleton';
import EmptyGridResource from '../../components/common/EmptyGridResource';
import useTheme from '../../hooks/useTheme';
import Tabs from '../../components/common/Tabs';
import { titleHTML } from '../../helpers/title.helper';
import MainSimulator from '../../modules/simulatorModule/components/MainSimulator';
import { useQueryParams } from '../../hooks/useQueryParams';
import useFetch from '../../hooks/useFetch';
import { getDownloadableFiles } from '../../redux/api/microlearnings';
import Downloadable from './Downloadable';
import { downloadableAdapter } from './adapter/microlearnings.adapter';
import DigitalLibrary from './DigitalLibrary';

const Content = () => {
    titleHTML('Biblioteca');

    // REDUX
    const dispatch = useDispatch();
    const microlearnings = useSelector(
        (state) => state.microlearnings.microlearnings
    );
    const listLoading = useSelector(
        (state) => state.microlearnings.listLoading
    );
    const { primary } = useTheme();

    const queryTab = useQueryParams();
    const initialTab = queryTab.get('tab') === 'simulador' ? 1 : 0;

    // STATE
    const [tab, setTab] = useState(initialTab);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [sharedItem, setSharedItem] = useState(null);

    const emptyItems = new Array(6).fill(null);

    // EFFECTS
    useEffect(() => {
        if (tab === 0 && microlearnings === null) {
            dispatch(microlearningsActions.getMicrolearningsRequest());
        }

        if (microlearnings != null && microlearnings.length > 0) {
            microlearnings.filter(
                (ml) =>
                    ml.fecha_hasta === 'False' ||
                    !ml.fecha_hasta ||
                    new Date(ml.fecha_hasta) > new Date()
            );
        }
    }, [microlearnings]);

    //descargables
    const { data: downloadable, loading } = useFetch({
        asyncFn: () => getDownloadableFiles(),
        // dependencies: [],
        adapter: downloadableAdapter,
    });

    // FUNCTIONS
    function handleTab(value) {
        setTab(value);
    }

    function handleOpenModal(id, nombre_publicacion) {
        setModalIsOpen(true);
        setSharedItem({
            lp_type: 'microcontent',
            type: 'recurso',
            id,
            nombre_publicacion,
        });
    }

    function handleCloseModal() {
        setModalIsOpen(false);
    }

    const renderCase = (tab) => {
        const caseType = {
            0:
                microlearnings === null || listLoading ? (
                    <EmptyGridResource
                        propsValues={emptyItems}
                        Card={MicrocontentCardSkeleton}
                    />
                ) : (
                    <GridResource
                        propsValues={microlearnings}
                        type={'micro'}
                        Card={MicrolearningsCard}
                        handleOpenModal={handleOpenModal}
                    />
                ),
            1: <MainSimulator />,
            2: (
                <Downloadable
                    downloadable={downloadable}
                    emptyItems={emptyItems}
                    loading={loading}
                />
            ),
            3: <DigitalLibrary />,
        };

        return caseType[tab];
    };

    const tabs = [
        { label: 'Microlearning', id: 0 },
        { label: 'Simulador 360', id: 1 },
        { label: 'Contenido descargable', id: 2 },
        { label: 'Biblioteca digital', id: 3 },
    ];
    // RETURN
    return (
        <ContentContainer>
            <SharedResource
                modalIsOpen={modalIsOpen}
                handleCloseModal={handleCloseModal}
                sharedItem={sharedItem}
            />
            <Tabs tabValue={tab} handleTabValue={handleTab} tabOptions={tabs} />
            <ContentMain>
                {microlearnings === null || listLoading ? (
                    <GridMain>
                        <EmptyGridResource
                            propsValues={emptyItems}
                            Card={MicrocontentCardSkeleton}
                        />
                    </GridMain>
                ) : (
                    <GridMain>{renderCase(tab)}</GridMain>
                )}
            </ContentMain>
        </ContentContainer>
    );
};

export default Content;

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    // First child div
    & > div:first-child {
        margin-left: -2rem;
        margin-top: -2rem;
        background-color: #fff;
        width: calc(100% + 4rem);
        justify-content: start;
    }
`;

const ContentMain = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow:
        0px 12px 24px -15px #0000001a,
        0px 0px 10px -6px #00000040;
`;

const GridMain = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 5px;
`;
