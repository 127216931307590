import { useEffect, useState } from 'react';
import {
    getHistoricalGrades,
    listActivitiesByAssignment,
    listStudentsByAssignment,
} from '../../redux/api/teacher';
import {
    historicalActivitiesAdapter,
    historicalGradesAdapter,
    historicalStudentsAdapter,
} from '../../components/templates/teacher/qualifications/adapters/historicalgrades.adapter';
import useFetch from '../useFetch';

const useHistoryGradebook = (id, page, limit, student, activity) => {
    const [gradesData, setGradesData] = useState([]);
    const [totalStudents, setTotalStudents] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    //Lista inicial de Estudiantes
    const {
        data: studentsList,
        loading: loading_studentsList,
        error: error_studentsList,
        // isError: isError_studentsList,
    } = useFetch({
        asyncFn: () => listStudentsByAssignment(id),
        adapter: historicalStudentsAdapter,
    });

    //Lista inicial de Actividades
    const {
        data: activitiesList,
        loading: loading_activitiesList,
        error: error_activitiesList,
        // isError: isError_activitiesList,
    } = useFetch({
        asyncFn: () => listActivitiesByAssignment(id),
        adapter: historicalActivitiesAdapter,
    });

    //Lista Inicial de Calificaciones históricas filtrada y paginada
    const { data, loading, error, isError } = useFetch({
        asyncFn: () => getHistoricalGrades(id, 0, limit, student, activity),
        adapter: historicalGradesAdapter,
    });
    useEffect(() => {
        if (data) {
            setTotalStudents(data.total);
            setGradesData([...data.grades]);
        }
    }, [data]);

    //Funcíon para actualizar búsqueda con filtros y paginación
    const fetchFilterGrades = async (
        id_assigment,
        page,
        limit,
        student,
        activity
    ) => {
        setIsLoading(true);
        // const exactCurrentPage = page === 0 ? 0 : page - 1;

        try {
            const data = await getHistoricalGrades(
                id_assigment,
                0,
                limit,
                student,
                activity
            );
            const adaptedData = historicalGradesAdapter(data);
            setGradesData([...adaptedData?.grades]);
            setTotalStudents(adaptedData?.total);
        } catch (error) {
            console.error('Error fetching grades:', error);
            return;
        } finally {
            setIsLoading(false);
        }
    };

    return {
        isLoading,
        loading,
        totalStudents,
        gradesData,
        error,
        fetchFilterGrades,
        loading_activitiesList,
        error_activitiesList,
        loading_studentsList,
        error_studentsList,
        studentsList,
        activitiesList,
    };
};

export default useHistoryGradebook;
