import { Modal } from '@mui/material';
import React from 'react';
import {
    CloseButton,
    Image,
    WrapperModal,
} from '../../../../styled-components';
import { Icon } from '@iconify/react';
import { Text } from '../../../common/Texts';
import Error from '../../../../assets/media/aden/error.png';

const ErrorUploadModal = (props) => {
    const { open, handleCloseModal } = props;

    return (
        <Modal onClose={handleCloseModal} open={open}>
            <WrapperModal
                widthModal="max-content"
                maxHeightModal="max-content"
                gapModal="1rem"
                topModal="50%"
                leftModal="50%"
            >
                <CloseButton onClick={handleCloseModal}>
                    <Icon icon="iconoir:cancel" width="24px" height="24px" />
                </CloseButton>
                <Image src={Error} width={'250px'} height={'250px'} />
                {<br></br>}
                <Text
                    fontSize="20px"
                    fontWeight={700}
                    color="#b31d15"
                    textAlign="center"
                    lineHeight="24px"
                >
                    ¡Ups! Ocurrió un error al guardar el documento.
                </Text>

                <Text
                    fontSize="16px"
                    fontWeight={700}
                    color="#616161"
                    textAlign="center"
                    lineHeight="24px"
                >
                    por favor comunícate con tu Asesor de documentación.
                </Text>
            </WrapperModal>
        </Modal>
    );
};

export default ErrorUploadModal;
