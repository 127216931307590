import { Icon } from '@iconify/react/dist/iconify.js';
import styled from 'styled-components';

const BenefitsCard = (props) => {
    const { isFromADEN } = props;

    if (isFromADEN)
        return (
            <Container>
                <h2>
                    ¿Cuáles son los <i>beneficios</i> que tú recibirás?
                </h2>
                <h3>
                    <label>
                        <Icon
                            icon="iconamoon:gift"
                            width="24"
                            height="24"
                            color="#fff"
                        />
                        200 USD
                    </label>
                    <Icon
                        icon="mi:arrow-right"
                        width="1.5rem"
                        height="1.5rem"
                        color="#fff"
                    />
                    <p>
                        Si la persona que refieres se matricula en un{' '}
                        <b>
                            MBA, Global MBA, Executive MBA o Doctorado en
                            Administración de Empresas.
                        </b>
                    </p>
                </h3>
                <h3>
                    <label>
                        <Icon
                            icon="iconamoon:gift"
                            width="24"
                            height="24"
                            color="#fff"
                        />
                        100 USD
                    </label>
                    <Icon
                        icon="mi:arrow-right"
                        width="1.5rem"
                        height="1.5rem"
                        color="#fff"
                    />
                    <p>
                        Si la persona que refieres se matricula en una{' '}
                        <b>Maestría especializada.</b>
                    </p>
                </h3>
                <h3>
                    <label>
                        <Icon
                            icon="iconamoon:gift"
                            width="24"
                            height="24"
                            color="#fff"
                        />
                        75 USD
                    </label>
                    <Icon
                        icon="mi:arrow-right"
                        width="1.5rem"
                        height="1.5rem"
                        color="#fff"
                    />
                    <p>
                        Si la persona que refieres se matricula en un{' '}
                        <b>Major.</b>
                    </p>
                </h3>
                <h3>
                    <label>
                        <Icon
                            icon="iconamoon:gift"
                            width="24"
                            height="24"
                            color="#fff"
                        />
                        50 USD
                    </label>
                    <Icon
                        icon="mi:arrow-right"
                        width="1.5rem"
                        height="1.5rem"
                        color="#fff"
                    />
                    <p>
                        Si la persona que refieres se matricula en un{' '}
                        <b>Programa Especializado.</b>
                    </p>
                </h3>
            </Container>
        );
    return (
        <Container>
            <h2>
                ¿Cuáles son los <i>beneficios</i> que tú recibirás?
            </h2>
            <h3>
                <label>
                    <Icon
                        icon="iconamoon:gift"
                        width="24"
                        height="24"
                        color="#fff"
                    />
                    200 USD
                </label>
                <Icon
                    icon="mi:arrow-right"
                    width="1.5rem"
                    height="1.5rem"
                    color="#fff"
                />
                <p>
                    Si la persona que refieres se matricula en un{' '}
                    <b>MBA o en un Global MBA.</b>
                </p>
            </h3>
            <h3>
                <label>
                    <Icon
                        icon="iconamoon:gift"
                        width="24"
                        height="24"
                        color="#fff"
                    />
                    100 USD
                </label>
                <Icon
                    icon="mi:arrow-right"
                    width="1.5rem"
                    height="1.5rem"
                    color="#fff"
                />
                <p>
                    Si la persona que refieres se matricula en una{' '}
                    <b>Maestría especializada.</b>
                </p>
            </h3>
            <h3>
                <label>
                    <Icon
                        icon="iconamoon:gift"
                        width="24"
                        height="24"
                        color="#fff"
                    />
                    75 USD
                </label>
                <Icon
                    icon="mi:arrow-right"
                    width="1.5rem"
                    height="1.5rem"
                    color="#fff"
                />
                <p>
                    Si la persona que refieres se matricula en una{' '}
                    <b>Especialización o Majors.</b>
                </p>
            </h3>
            <Line />
            <h2>
                ¿Y qué beneficio obtiene <i>el referido</i>?
            </h2>
            <span>
                <p>
                    15% de bonificación en{' '}
                    <b>toda nuestra propuesta académica.</b>
                </p>
                <p>
                    <i>No es acumulable con otros beneficios.</i>
                </p>
            </span>
        </Container>
    );
};

export default BenefitsCard;

const Container = styled.div`
    width: calc(100% - 4rem);
    height: 60vh;
    border-radius: 20px;
    background: linear-gradient(180deg, #e51a1a 0%, #b31d15 100%);
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0 2rem;

    h2 {
        font-size: 24px;
        font-weight: 600;
    }

    h3 {
        font-size: 24px;
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: 1rem;

        label {
            display: flex;
            align-items: center;
            gap: 8px;
            width: 130px;
        }
    }

    p {
        max-width: 85%;
        font-weight: 400;
    }

    span {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
`;

const Line = styled.div`
    width: 100%;
    height: 1px;
    background: #fff;
`;
