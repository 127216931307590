import styled from 'styled-components';
import { Icon } from '@iconify/react/dist/iconify.js';

const InfoCardCommon = () => {
    return (
        <InfoCard>
            <p>
                <b>¿Cuáles son los beneficios que tú recibirás?</b>
            </p>
            <span>
                <label>
                    <Icon
                        icon="iconamoon:gift"
                        width="24"
                        height="24"
                        color="#fff"
                    />
                    200 USD
                </label>
                <Icon
                    icon="mi:arrow-right"
                    width="1.5rem"
                    height="1.5rem"
                    color="#fff"
                />
                <p>
                    Si la persona que refieres se matricula en un MBA o en un
                    GLOBAL MBA.
                </p>
            </span>
            <span>
                <label>
                    <Icon
                        icon="iconamoon:gift"
                        width="24"
                        height="24"
                        color="#fff"
                    />
                    100 USD
                </label>
                <Icon
                    icon="mi:arrow-right"
                    width="1.5rem"
                    height="1.5rem"
                    color="#fff"
                />
                <p>
                    Si la persona que refieres se matricula en una MAESTRIA
                    ESPECIALIZADA.
                </p>
            </span>
            <span>
                <label>
                    <Icon
                        icon="iconamoon:gift"
                        width="24"
                        height="24"
                        color="#fff"
                    />
                    75 USD
                </label>
                <Icon
                    icon="mi:arrow-right"
                    width="1.5rem"
                    height="1.5rem"
                    color="#fff"
                />
                <p>
                    Si la persona que refieres se matricula en un MAJOR o
                    PROGRAMA ESPECIALIZADO.
                </p>
            </span>
            <hr />
            <p>
                <b>¿Y qué beneficio obtiene el referido?</b>
            </p>
            <span>
                <Icon
                    icon="iconamoon:gift"
                    width="24"
                    height="24"
                    color="#fff"
                />
                <p>15% de bonificación en TODA NUESTRA PROPUESTA ACADÉMICA.</p>
            </span>
        </InfoCard>
    );
};

export default InfoCardCommon;

const InfoCard = styled.div`
    width: 50%;
    height: 540px;
    border-radius: 20px;
    box-shadow:
        0px 12px 24px -15px #0000001a,
        0px 0px 10px -6px #00000040;
    background: rgb(229, 26, 26);
    background: linear-gradient(
        180deg,
        rgba(229, 26, 26, 1) 0%,
        rgba(179, 29, 21, 1) 100%
    );
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: start;
    padding: 0 3rem;
    z-index: 99;

    @media (width < 1300px) {
        width: calc(100vw - 14rem);
    }

    @media (width < 768px) {
        width: calc(100% - 8rem);
    }

    p {
        font-size: 20px;
        font-weight: 500;
        color: #fff;

        @media (width < 768px) {
            font-size: 13px;
        }
    }

    span {
        display: flex;
        gap: 1rem;

        @media (width < 768px) {
            svg {
                min-height: 2rem;
                min-width: 2rem;
            }
        }

        label {
            height: fit-content;
            min-width: 120px;
            max-width: 120px;
            display: flex;
            gap: 1rem;
            font-size: 20px;
            font-weight: 500;
            color: #fff;

            @media (width < 768px) {
                font-size: 13px;
                min-width: 90px;
                max-width: 90px;
            }
        }
    }

    hr {
        width: 100%;
        height: 0.5px;
        background-color: #fff;
    }
`;
