import axios from 'axios';
import { axiosErrorFormatter } from '../../../../utils/error.handler';

export async function getMessages(roomId, size = 0, offset = 0) {
    const URL = `${process.env.REACT_APP_CHAT_V2}/messages/${roomId}?size=${size}&offset=${offset}`;

    try {
        const request = await axios.get(URL);
        return request.data;
    } catch (error) {
        return axiosErrorFormatter(error);
    }
}
