import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Card, Button } from '@mui/material';
import ErrorResponse from '../../../common/ErrorResponse';
import useGradebook from '../../../../hooks/teacher/useGradebook';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import HistoricalGradebookSection from './HistoricalGradebookSection';
import GradebookSection from './GradebookSection';

const GradeBook = (props) => {
    const { id, viewAcademic = false } = props;
    const [historyInfo, setHistoryInfo] = useState(false);

    // CUSTOM HOOKS
    const { students, isLoading } = useGradebook(id);

    const goToGradeHistory = useCallback(() => {
        setHistoryInfo(true);
    }, []);

    const goToGradeBook = useCallback(() => {
        setHistoryInfo(false);
    }, []);

    if (students?.length === 0 && !isLoading) {
        return (
            <CardFake>
                <Container>
                    <ErrorWrapper>
                        <ErrorResponse message="No hay estudiantes en esta asignatura" />
                    </ErrorWrapper>
                </Container>
            </CardFake>
        );
    }
    return (
        <CardFake>
            <Container>
                {historyInfo ? (
                    <HistoryButton
                        colorProps={historyInfo}
                        onClick={goToGradeBook}
                    >
                        <ArrowBackRoundedIcon />
                        Volver
                    </HistoryButton>
                ) : (
                    <HistoryButton
                        colorProps={historyInfo}
                        onClick={goToGradeHistory}
                    >
                        <ReplayOutlinedIcon />
                        Historial de calificaciones
                    </HistoryButton>
                )}
                {historyInfo ? (
                    <HistoricalGradebookSection id={id} />
                ) : (
                    <GradebookSection id={id} />
                )}
            </Container>
        </CardFake>
    );
};

export default GradeBook;

const CardFake = styled(Card)`
    grid-area: container;
    padding: 1rem;
    border-radius: 20px;
    box-shadow:
        0px 8px 22px -6px rgba(24, 39, 75, 0.12),
        0px 14px 64px -4px rgba(24, 39, 75, 0.12);
`;

const Container = styled.div`
    min-height: auto;
    width: 98%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: 1rem;
`;

const ErrorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 500px;
`;

const HistoryButton = styled(Button)`
    //true: historyInfo _  false: gradeBook actual
    color: ${(props) => (props.colorProps ? '#616161' : '#b31d15')};
    border: ${(props) => (props.colorProps ? 'none' : '1px solid #b31d15')};
    border-radius: 100px;
    background-color: transparent;
    gap: 1rem;
    padding: 0.6rem 1rem !important;
    font-size: 1rem;
    align-self: end;

    :hover {
        background-color: ${(props) =>
            props.colorProps ? '#F9F9F9' : '#b31d15'};
        color: ${(props) => (props.colorProps ? '#616161' : '#fafafa')};
    }
`;
