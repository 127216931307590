import styled from 'styled-components';
import React from 'react';

// Material UI
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Hooks
import useTheme from '../../hooks/useTheme';

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const TabsComponent = (props) => {
    const { tab, handleTab, tabs, variant, fullWidth, center = false } = props;

    const tabVariant = variant !== undefined ? variant : 'scrollable';
    const { primary } = useTheme();

    const handleClick = (event) => {
        // Evitar la redirección al hacer clic en el tab
        event.preventDefault();
    };

    // RETURN
    return (
        <Container>
            <FakeAppBar
                fullWidth={fullWidth}
                center={center}
                position="static"
                color="secondary"
            >
                <TabsFake
                    value={tab}
                    onChange={handleTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={tabVariant}
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    color={primary}
                >
                    {tabs.map((item) => (
                        <FakeTab
                            component="a"
                            href={item?.href}
                            onClick={handleClick}
                            label={item.label}
                            key={item.id}
                            {...a11yProps(item.id)}
                            value={item.id}
                            disabled={item.disabled}
                        />
                    ))}
                </TabsFake>
            </FakeAppBar>
        </Container>
    );
};

export default TabsComponent;

const Container = styled.div`
    display: flex;
    width: 100%;
`;

const FakeAppBar = styled(AppBar)`
    background-color: #ffffff;
    box-shadow: none;
    z-index: 1;
    width: calc(100% - 1rem);
    align-items: ${(p) => (p.center ? 'center' : 'none')};
    border-radius: 20px 20px 0 0;
`;

const FakeTab = styled(Tab)`
    text-transform: none;
`;

const TabsFake = styled(Tabs)`
    .MuiTabs-flexContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-around !important;
        width: 100%;
    }
`;
