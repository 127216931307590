import { Modal } from '@mui/material';
import React from 'react';
import {
    CloseButton,
    Image,
    WrapperModal,
} from '../../../../styled-components';
import { Icon } from '@iconify/react';
import { Text } from '../../../common/Texts';
import WorkInProgress from '../../../../assets/media/aden/work-in-progress.png';

const AwaitModal = (props) => {
    const { open, handleCloseModal, title } = props;

    return (
        <Modal onClose={handleCloseModal} open={open}>
            <WrapperModal
                widthModal="max-content"
                maxHeightModal="max-content"
                gapModal="1rem"
                topModal="50%"
                leftModal="50%"
            >
                <CloseButton onClick={handleCloseModal}>
                    <Icon icon="iconoir:cancel" width="24px" height="24px" />
                </CloseButton>
                <Image src={WorkInProgress} width={'250px'} height={'250px'} />
                <Text
                    fontSize="16px"
                    fontWeight={700}
                    color="#616161"
                    textAlign="center"
                    lineHeight="24px"
                >
                    Aguarda unos instantes, se está enviando tu documento:
                    {<br></br>}
                    {title}
                </Text>
            </WrapperModal>
        </Modal>
    );
};

export default AwaitModal;
