import { LargeLinearProgress, CustomChip } from '../../../../styled-components';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import React from 'react';

// Components
import SkeletonLoading from '../../../../components/common/SkeletonLoading';
import GenericSlider from '../../../../components/common/GenericSlider';
import LazyImg from '../../../../components/common/LazyImg';
import { Text } from '../../../../components/common/Texts';

// Material UI
import { Card } from '@mui/material';

// Hooks
import usePrograms from '../../../../hooks/common/usePrograms';

// Helpers
import { checkRoles } from '../../../../helpers/roles.helper';

// Assets
import NoCoursesFound from '../../../../assets/media/campus/no-course_found.png';

// Hooks
import useWindowWidth from '../../../../hooks/useWindowWidth';
import FontAwesomeIcon from '../../../../components/common/FontAwesomeIcon';

const ProgramsSlider = () => {
    const { programs } = usePrograms();

    if (!programs) {
        return <SkeletonLoading height="319px" width="100%" />;
    }

    if (programs.length === 0) {
        return (
            <CardFake>
                <NotFoundWrapper>
                    <Text color="#616161" fontSize="15px" fontWeight="500">
                        Aún no te encuentras matriculado en ningun programa.
                    </Text>
                    <LazyImg
                        src={NoCoursesFound}
                        width="300px"
                        height="fit-content"
                        backgroundColor="#ffffff"
                    />
                </NotFoundWrapper>
            </CardFake>
        );
    }

    return (
        <>
            {checkRoles(['alumno', 'Admin', 'visita']) && (
                <CardFake data-tut="reactour__ProgramsSlider">
                    <GenericSlider
                        array={programs}
                        card={CardP}
                        to={'/mi-progreso'}
                        title={'Mis programas'}
                        type={'programs'}
                    />
                </CardFake>
            )}
        </>
    );
};

export default ProgramsSlider;

const CardFake = styled(Card)`
    box-sizing: border-box;
    width: 100%;
    box-shadow:
        0px 3px 6px 0px rgba(0, 0, 0, 0.16),
        0px 3px 6px 0px rgba(0, 0, 0, 0.23);
    border-radius: 30px;
    background-color: #fff;
    padding: 32px 24px;
    height: 319px;
    border: 1px solid #b31d15;

    @media (max-width: 1136px) {
        width: calc(100%);
        height: 365px;
        margin-top: 30px;
        padding: 0;
    }

    @media (max-width: 1024px) {
        width: 100%;
        height: 380px;
        /* padding: 32px 24px; */
        margin-top: 0;
        padding: 1rem 0 0 1rem;
    }
`;

const CardP = (props) => {
    const { value } = props;

    const windowWidth = useWindowWidth();

    /* const hash = encode.encode(String(value.id), "base64"); */
    const history = useHistory();

    const handleLink = () => {
        let uri = './mi-progreso';
        history.push(uri);
    };

    return (
        <CardWrapp onClick={handleLink}>
            <ProgressContent>
                <Text color="#616161" fontSize="15px" fontWeight="500">
                    PROGRAMA
                </Text>
                <Text
                    color="#222"
                    fontSize={windowWidth >= 1260 ? '18px' : '14px'}
                    fontWeight="400"
                >
                    {value.program}
                </Text>
                <Progress>
                    <IconWrapper>
                        <FontAwesomeIcon
                            icon="fa-solid fa-user-vneck"
                            size={16}
                        />
                    </IconWrapper>
                    <LinearProgressWrapper>
                        <LargeLinearProgress
                            variant="determinate"
                            value={
                                // Esta validación se realiza por manqueada del back
                                Math.trunc(value.total_progress) > 100
                                    ? 100
                                    : Math.trunc(value.total_progress)
                            }
                        />
                    </LinearProgressWrapper>
                    <Text color="#616161" fontSize="15px" fontWeight="700">
                        {
                            // Esta validación se realiza por manqueada del back
                            Math.trunc(value.total_progress) > 100
                                ? 100
                                : Math.trunc(value.total_progress)
                        }
                        %
                    </Text>
                    <IconWrapper>
                        <FontAwesomeIcon
                            icon="fa-solid fa-user-graduate"
                            size={16}
                        />
                    </IconWrapper>
                </Progress>
            </ProgressContent>
            <StateWrapper>
                <Text color="#616161" fontSize="14px" fontWeight="400">
                    Avance de las asignaturas que componen este programa:
                </Text>
                <StateContent>
                    <StateChip
                        icon={
                            <FontAwesomeIcon
                                icon="fa-regular fa-check"
                                size={24}
                            />
                        }
                        color="tertiary"
                        bgColor="#F7F8FD"
                        svgColor="#5E80DB"
                        label={
                            <>
                                <BoldNumber>
                                    {value.in_progress ?? 0}
                                </BoldNumber>{' '}
                                En curso
                            </>
                        }
                        width="190px"
                        variant="outlined"
                    />
                    <StateChip
                        icon={
                            <FontAwesomeIcon
                                icon="fa-light fa-clock"
                                size={24}
                            />
                        }
                        color="tertiary"
                        bgColor="#F9F5EC"
                        svgColor="#C29F43"
                        label={
                            <>
                                <BoldNumber>{value.pending ?? 0}</BoldNumber>{' '}
                                Por iniciar
                            </>
                        }
                        width="190px"
                        variant="outlined"
                    />
                    <StateChip
                        icon={
                            <FontAwesomeIcon
                                icon="fa-light fa-graduation-cap"
                                size={20}
                            />
                        }
                        color="tertiary"
                        bgColor="#F7FDFB"
                        svgColor="#1E8065"
                        label={
                            <>
                                <BoldNumber>{value.finished ?? 0}</BoldNumber>{' '}
                                Finalizados
                            </>
                        }
                        width="190px"
                        variant="outlined"
                    />
                </StateContent>
            </StateWrapper>
        </CardWrapp>
    );
};

const CardWrapp = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    cursor: pointer;
    /* grid-template-columns: repeat(2, 1fr); */

    @media (max-width: 1600px) {
        /* grid-template-columns: 1fr; */
        height: auto;
    }

    @media (max-width: 1336px) {
        gap: 1rem;
    }
`;

const ProgressContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1.3rem;
    min-width: 400px;
    height: fit-content;
    overflow: hidden;
    white-space: nowrap;
`;

const StateContent = styled.div`
    display: flex;
    gap: 2rem;
    max-width: 600px;

    @media (max-width: 1136px) {
        flex-direction: column;
        align-items: center;
        gap: 7px;
    }
`;

const Progress = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    @media (max-width: 1260px) {
        justify-content: flex-start;
        width: calc(100% - 100px);
    }
`;

const LinearProgressWrapper = styled.div`
    box-sizing: border-box;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    height: 16px;
    background-color: #eaeaea;

    @media (max-width: 1336px) {
        justify-content: flex-start;
    }
`;

const IconWrapper = styled.div`
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: flex-end;
    border-radius: 50%;
    padding: 0.3rem;
    border: 2px solid #616161;
    background-color: #e5e5e5;

    svg {
        width: 20px;
        height: 19px;
        color: #616161;
    }

    @media screen and (max-width: 768px) {
        svg {
            width: 16px;
            height: 15px;
        }
    }
`;

const StateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 1rem;
`;

const NotFoundWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;

    p {
        text-align: center;
        width: 250px;
    }
`;

const StateChip = styled(CustomChip)`
    border: 1px solid ${({ svgColor }) => !!svgColor && svgColor};

    @media (max-width: 1260px) {
        font-size: 12px;
        width: 150px;

        svg {
            font-size: 16px;
        }
    }
`;

const BoldNumber = styled.span`
    font-weight: bold;
`;
