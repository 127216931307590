import styled from 'styled-components';
import React from 'react';

// Components
import { Text } from '../../../../components/common/Texts';
import { Img } from '../../../../components/common/Image';

// Material UI
import { Button } from '@mui/material';

// Assets
import giftAnimation from '../../../../assets/media/gif/animacion-referidos.gif';

// Hooks
import useRedirect from '../../../../hooks/useRedirect';
import { useSelector } from 'react-redux';

const MyReferralsCard = () => {
    //HOOKS
    const { handleRedirection } = useRedirect();

    const { partner_id, name } = useSelector((state) => state.auth.user);

    const comportamientoInfo = {
        partner_id: partner_id,
        partner_name: name,
    };

    return (
        <Wrapper>
            <CardWrapper>
                <Text color="#B31D15" fontSize="20px" fontWeight="700">
                    Mis referidos
                </Text>
                <Image src={giftAnimation} w="30" />
                <TextWrapper>
                    <Text color="#222222" fontSize="16px" fontWeight="400">
                        ¡Recomendar ADEN, <br />
                        <b>
                            <i>siempre es una buena idea!</i>
                        </b>
                    </Text>
                </TextWrapper>
                <ReferralsButton
                    onClick={() => handleRedirection('/mis-referidos')}
                    variant="contained"
                    data-behaviour-action={'home_referir'}
                    // Event detail
                    data-behaviour-detail={JSON.stringify(comportamientoInfo)}
                >
                    Ver más
                </ReferralsButton>
            </CardWrapper>
        </Wrapper>
    );
};

export default MyReferralsCard;

const Wrapper = styled.section`
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;

    @media (max-width: 1265px) {
        width: 100%;
    }
`;

const CardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    width: 256px;
    min-height: 436px;
    height: calc(100% - 4rem);
    border-radius: 30px;
    background-color: #fbfbfb;
    z-index: 1;
    box-shadow:
        0px 3px 6px 0px rgba(0, 0, 0, 0.16),
        0px 3px 6px 0px rgba(0, 0, 0, 0.23);
    padding: 2rem;

    @media (max-width: 1440px) {
        width: calc(100% - 4rem);
    }
`;

const TextWrapper = styled.div`
    width: 100%;
    padding: 1.2rem;
    text-align: center;
    display: flex;
    justify-content: center;

    p {
        display: inline-block;
    }

    b {
        color: #b31d15;
        font-size: 18px;
        font-weight: 900;
    }
`;

const Image = styled(Img)`
    width: 180px;
    height: 180px;
    background-color: #fff;
`;

const ReferralsButton = styled(Button)`
    border-radius: 50px;
    padding: 0.5rem 1.5rem;
`;
