import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// COMPONENTS
import Index from './app/landing/Index';
import LoginPage from './pages/auth/LoginPage';
import Campus from './pages/campus/Campus';
import Directory from './pages/networking/Directory';
import Workshops from './pages/workshops/Workshops';
import Content from './pages/microlearning/Content';
import Profile from './pages/profile/Profile';
import PublicProfile from './pages/profile/PublicProfile';
import Microlearnings from './pages/microlearning/Microlearnings';
import PrivacyPolicy from './app/landing/PrivacyPolicy';
import Academic from './pages/academicInfo/Academic';
import AcademicAdvisor from './pages/academicInfo/AcademicAdvisor';
import Chat from './pages/chat/Chat';
import ChatV2 from './pages/chatV2/Chat';
import Logout from './pages/auth/Logout';
import RegisterPage from './pages/auth/RegisterPage';
import Business from './pages/ondemand/Business';
import Events from './pages/events/Events';
import Dracma from './pages/dragmas/Dracma';
import RegisterOnDemand from './pages/ondemand/RegisterOnDemand';
import Tickets from './pages/tickets/Tickets';
import Iframe from './pages/networking/Iframe';

// ROUTES
import PublicRoute from './routes/PublicRoute';
import NotFound from './pages/error/NotFound';
import RedirectTo from './pages/RedirectTo';
import IA from './pages/recommendation/IA';
import Certificate from './pages/certificate/Certificate';
import CourseSubject from './pages/subject/CourseSubject';
import Challege from './pages/ondemand/Challenge';
import Course from './pages/course/Course';
import RegisterPageStudent from './pages/auth/RegisterPageStudent';
import Asistencia from './pages/asistencia/Asistencia';
import Dashboard from './pages/dashboard/Dashboard';
import OneCourseTeacher from './pages/dashboard/OneCourseTeacher';
import SpeedGrader from './pages/speedgrader/SpeedGrader';
import CourseTeacher from './pages/course/CourseTeacher';
import AcademicRoute from './routes/AcademicRoute';
import AcademicDashboard from './pages/academicPages/AcademicDashboard';
import TeacherRoute from './routes/TeacherRoute';
import AcademicStudents from './pages/academicPages/AcademicStudents';
import AcademicOneStudent from './pages/academicPages/AcademicOneStudent';
import MyFavs from './pages/favorites/MyFavs';
import AuthPage from './pages/auth/AuthPage';
import AcademicTeachers from './pages/academicPages/AcademicTeachers';
import PorvenirIC from './pages/incompany/Porvenir';
import StudentsPage from './pages/students/StudentsPage';
import FacultyPage from './pages/faculty/FacultyPage';
import ProgramAcademic from './pages/program/ProgramAcademic';
import CommonRoute from './routes/CommonRoute';
import StudentRoute from './routes/StudentRoute';
import OndemandRoute from './routes/OndemandRoute';
import ErrorRoute from './routes/ErrorRoute';
import ProfileTeacher from './pages/profile/ProfileTeacher';
import Progress from './pages/progress/Progress';
import StudyPlan from './pages/progress/StudyPlan';
import Notification from './pages/notifications/Notification';
import ForumSynchronous from './pages/events/ForumSynchronous';
import Referred from './pages/referred/Referred';
import EseadeIC from './pages/incompany/Eseade';
import LoadingPage from './pages/loading/LoadingPage';
import Support from './pages/support/Support';
import MyReferrals from './pages/referred/MyReferrals';
import CoursesAA from './pages/course/CoursesAA';
import AutoLoginRoute from './routes/AutoLoginRoute';
import Blog from './pages/ondemand/Blog';
import Article from './pages/ondemand/Article';
import AcademicCourse from './pages/academicPages/AcademicCourse';
import AlabAuth from './pages/auth/AlabAuth';
import OneStudentPage from './pages/students/OneStudentPage';
import UpdatedSubject from './pages/updatedSubject/UpdatedSubject';
import MyCertificates from './pages/myCertificates/MyCertificates';

// Sección carreras
import EducationalOffer from './modules/educationalOffer';
import Visualizer from './modules/acropolisStudying/Visualizer';
import Cookies from './pages/cookies/Cookies';
import AfterSales from './pages/afterSales/AfterSales';
import EventAttendace from './pages/asistencia/EventAttendance';
import AcademicOneStudentRepo from './pages/academicPages/AcademicOneStudentRepo';
import AcademicIndividualSubjects from './pages/academicPages/AcademicIndividualSubjects';
import GeneralAnnouncements from './pages/announcements/GeneralAnnouncements';

// ADENUPA
import PublicProfileTeacher from './pages/profile/PublicProfileTeacher';
import ChatCommunity from './pages/chatV2/components/community/ChatCommunity';
import Achievement from './pages/achievement/Achievement';

const Routes = () => {
    return (
        <Switch>
            {/* RUTAS PUBLICAS ----------------------------------------------------------------------- */}

            {/* AutoLogin */}
            <AutoLoginRoute path="/autologin" exact />

            {/* Landing */}
            <PublicRoute layout="common" path="/" exact component={Index} />
            <PublicRoute
                layout="common"
                path="/privacy-policy"
                exact
                component={PrivacyPolicy}
            />
            <PublicRoute
                layout="common"
                path="/postventa"
                exact
                component={AfterSales}
            />

            {/* Auth */}
            <PublicRoute
                layout="common"
                path="/auth"
                exact
                component={AuthPage}
            />
            <PublicRoute
                layout="common"
                path="/auth/recover/:recover_id"
                exact
                component={AuthPage}
            />
            <PublicRoute
                layout="common"
                path="/auth/login"
                exact
                component={LoginPage}
            />
            <PublicRoute
                layout="common"
                path="/alab/login"
                exact
                component={AlabAuth}
            />
            <PublicRoute
                layout="common"
                path="/auth/signup/:id"
                exact
                component={RegisterPageStudent}
            />
            <PublicRoute
                layout="common"
                path="/auth/registration"
                exact
                component={RegisterPage}
            />
            {/* Paginas InCompany */}
            <PublicRoute
                layout="common"
                path={`/ic/porvenir`}
                component={PorvenirIC}
            />
            <PublicRoute
                layout="common"
                path={`/eseade`}
                component={EseadeIC}
            />
            <PublicRoute
                layout="common"
                path="/ondemand/:ondemand_id"
                component={RegisterOnDemand}
            />

            {/* E-Commerce */}
            {/* <PublicRoute
                layout="e-commerce"
                blockRedirect={true}
                path="/producto/:id"
                exact
                component={LandingProduct}
            /> */}
            {/* Referidos */}
            <PublicRoute
                layout="certificate"
                blockRedirect={true}
                path="/referidos/:id"
                exact
                component={Referred}
            />
            <PublicRoute
                layout="certificate"
                blockRedirect={true}
                path="/referidos"
                exact
                component={Referred}
            />
            <PublicRoute
                layout="certificate"
                blockRedirect={true}
                path="/logros/:repo_id/:id"
                exact
                component={Achievement}
            />
            {/* Certificado */}
            <PublicRoute
                layout="certificate"
                blockRedirect={true}
                path="/certificate/:obj/:user"
                component={Certificate}
            />

            <PublicRoute
                layout="common"
                blockRedirect={true}
                path="/asistencia/:id"
                component={EventAttendace}
            />

            {/* Redirect default */}
            <Route path="/redirect" exact component={RedirectTo} />

            {/** RUTAS PRIVADAS -------------------------------------------------------------------------*/}

            <CommonRoute
                layout="common"
                path="/reload"
                exact
                component={Cookies}
                blockedRoles={[]}
            />

            {/* Auth */}
            <CommonRoute
                layout="common"
                path="/logout"
                exact
                component={Logout}
                blockedRoles={[
                    'alumno',
                    'docente',
                    'academico',
                    'visita',
                    'ondemand',
                ]}
            />

            {/* Alumno */}
            <StudentRoute
                layout="common"
                path="/asignatura/:id"
                exact
                component={CourseSubject}
                blockedRoles={['docente', 'academico']}
                permissionRoles={['alumno', 'ondemand']}
            />
            <StudentRoute
                layout="common"
                path="/asignatura-actualizada/:id"
                exact
                component={UpdatedSubject}
                blockedRoles={['docente', 'academico']}
                permissionRoles={['alumno', 'ondemand']}
            />
            <StudentRoute
                layout="common"
                path="/mis-certificados"
                exact
                component={MyCertificates}
                blockedRoles={['docente', 'academico']}
                permissionRoles={['alumno', 'ondemand']}
            />
            <CommonRoute
                layout="common"
                path="/oferta-educativa"
                title="Oferta educativa"
                exact
                component={EducationalOffer}
                blockedRoles={[]}
                permissionRoles={[
                    'docente',
                    'alumno',
                    'visita',
                    'ondemand',
                    'academico',
                ]}
            />
            <CommonRoute
                layout="common"
                path="/anuncios"
                title="Anuncios"
                exact
                component={GeneralAnnouncements}
                blockedRoles={[]}
                permissionRoles={[
                    'docente',
                    'alumno',
                    'visita',
                    'ondemand',
                    'academico',
                ]}
            />
            <StudentRoute
                layout="common"
                path="/mi-progreso"
                title="Mi progreso"
                exact
                component={Progress}
                blockedRoles={['docente', 'academico', 'ondemand']}
                permissionRoles={['alumno', 'visita']}
            />
            <StudentRoute
                layout="common"
                path="/mi-progreso/:id"
                exact
                component={StudyPlan}
                blockedRoles={['docente', 'academico', 'ondemand']}
                permissionRoles={['alumno', 'visita']}
            />
            <StudentRoute
                layout="common"
                path="/informacion-academica"
                title="Información académica"
                exact
                component={Academic}
                blockedRoles={['docente', 'academico', 'ondemand', 'visita']}
                permissionRoles={['alumno']}
            />
            <StudentRoute
                layout="common"
                path="/mi-academic-advisor"
                title="Mi Academic Advisor"
                exact
                component={AcademicAdvisor}
                blockedRoles={['docente', 'academico', 'ondemand', 'visita']}
                permissionRoles={['alumno']}
            />

            {/* Ondemand */}
            <OndemandRoute
                layout="common"
                path="/mi-empresa"
                exact
                component={Business}
                blockedRoles={['docente', 'academico']}
                permissionRoles={['ondemand']}
            />

            {/* Profesor */}
            <TeacherRoute
                layout="common"
                path="/profesor/dashboard"
                title="ADEN"
                exact
                component={Dashboard}
                blockedRoles={['alumno', 'visita', 'ondemand', 'academico']}
                permissionRoles={['docente']}
            />
            {/* <TeacherRoute
                layout="common"
                path="/profesor/perfil"
                exact
                component={ProfileTeacher}
                blockedRoles={['ondemand', 'alumno', 'visita', 'academico']}
                permissionRoles={['docente']}
            /> */}
            <TeacherRoute
                layout="common"
                path="/profesor/calificador/:hash"
                title="Calificador"
                exact
                component={SpeedGrader}
                blockedRoles={['alumno', 'visita', 'ondemand']}
                permissionRoles={['docente', 'academico']}
            />
            <TeacherRoute
                layout="common"
                path="/profesor/cursos-programas"
                title="Asignaturas"
                exact
                component={CourseTeacher}
                blockedRoles={['alumno', 'visita', 'ondemand', 'academico']}
                permissionRoles={['docente']}
            />
            <TeacherRoute
                layout="course"
                path="/profesor/curso/:id"
                exact
                component={OneCourseTeacher}
                blockedRoles={['alumno', 'visita', 'ondemand', 'academico']}
                permissionRoles={['docente']}
            />
            <TeacherRoute
                layout="common"
                path="/profesor/alumno/:id"
                exact
                component={OneStudentPage}
                blockedRoles={['alumno', 'visita', 'ondemand']}
                permissionRoles={['docente', 'academico']}
            />
            <TeacherRoute
                layout="common"
                path="/profesor/alumnos"
                title="Alumnos"
                exact
                component={StudentsPage}
                blockedRoles={['alumno', 'visita', 'ondemand', 'academico']}
                permissionRoles={['docente']}
            />
            <TeacherRoute
                layout="common"
                path="/profesor/faculty"
                title="Honorarios y liquidaciones"
                exact
                component={FacultyPage}
                blockedRoles={['alumno', 'visita', 'ondemand', 'academico']}
                permissionRoles={['docente']}
            />
            {/* Académico */}
            <AcademicRoute
                path="/academico"
                title="ADEN"
                exact
                component={AcademicDashboard}
                blockedRoles={['alumno', 'visita', 'ondemand', 'docente']}
                permissionRoles={['academico']}
            />
            <AcademicRoute
                path="/academico/alumnos"
                title="Mis alumnos"
                exact
                component={AcademicStudents}
                blockedRoles={['alumno', 'visita', 'ondemand', 'docente']}
                permissionRoles={['academico']}
            />
            <AcademicRoute
                path="/academico/alumno/:id"
                exact
                component={AcademicOneStudent}
                blockedRoles={['alumno', 'visita', 'ondemand', 'docente']}
                permissionRoles={['academico']}
            />
            <AcademicRoute
                path="/academico/alumno-repo/:id"
                exact
                component={AcademicOneStudentRepo}
                blockedRoles={['alumno', 'visita', 'ondemand', 'docente']}
                permissionRoles={['academico']}
            />
            <AcademicRoute
                path="/academico/alumno-repo/asignaturas/:id"
                title="Alumno 360"
                exact
                component={AcademicIndividualSubjects}
                blockedRoles={['alumno', 'visita', 'ondemand', 'docente']}
                permissionRoles={['academico']}
            />
            <AcademicRoute
                path="/academico/profesores"
                title="Profesores"
                exact
                component={AcademicTeachers}
                blockedRoles={['alumno', 'visita', 'ondemand', 'docente']}
                permissionRoles={['academico']}
            />
            <AcademicRoute
                path="/academico/cursos-programas"
                title="Mis asignaturas"
                exact
                component={CoursesAA}
                blockedRoles={['alumno', 'visita', 'ondemand', 'docente']}
                permissionRoles={['academico']}
            />
            <AcademicRoute
                path="/academico/programa/:id"
                exact
                component={ProgramAcademic}
                blockedRoles={['alumno', 'visita', 'ondemand', 'docente']}
                permissionRoles={['academico']}
            />
            <AcademicRoute
                path="/academico/curso/:id"
                exact
                component={AcademicCourse}
                blockedRoles={['alumno', 'visita', 'ondemand', 'docente']}
                permissionRoles={['academico']}
            />
            <AcademicRoute
                layout="common"
                path="/academico/calificador/:hash"
                exact
                component={SpeedGrader}
                blockedRoles={['alumno', 'visita', 'ondemand', 'docente']}
                permissionRoles={['academico']}
            />
            {/* Usuario en comun */}
            <CommonRoute
                layout="common"
                path="/campus"
                title="ADEN"
                exact
                component={Campus}
                blockedRoles={['docente', 'academico']}
                permissionRoles={['ondemand', 'alumno', 'visita']}
            />
            {/* Visualizador prueba */}
            <CommonRoute
                layout="common"
                path="/view/:type/:id"
                exact
                component={Visualizer}
                blockedRoles={[]}
                permissionRoles={[
                    'ondemand',
                    'alumno',
                    'visita',
                    'docente',
                    'academico',
                ]}
            />
            <CommonRoute
                layout="iframe"
                path="/chatear"
                exact
                component={Iframe}
                blockedRoles={['academico']}
                permissionRoles={['ondemand', 'alumno', 'docente', 'visita']}
            />
            <CommonRoute
                layout="iframe"
                path="/desafio"
                exact
                component={Challege}
                blockedRoles={['docente', 'academico']}
                permissionRoles={['ondemand', 'alumno', 'visita']}
            />
            <CommonRoute
                layout="common"
                path="/perfil"
                title="Perfil"
                exact
                component={Profile}
                blockedRoles={[]}
                permissionRoles={[
                    'ondemand',
                    'alumno',
                    'visita',
                    'academico',
                    'docente',
                ]}
            />
            <CommonRoute
                layout="common"
                path="/perfil-publico/:id"
                exact
                component={PublicProfile}
                blockedRoles={[]}
                permissionRoles={[
                    'ondemand',
                    'alumno',
                    'visita',
                    'docente',
                    'academico',
                ]}
            />
            <CommonRoute
                layout="common"
                path="/perfil-profesor/:id"
                exact
                component={PublicProfileTeacher}
                blockedRoles={[]}
                permissionRoles={[
                    'ondemand',
                    'alumno',
                    'visita',
                    'docente',
                    'academico',
                ]}
            />
            {/* <CommonRoute
                layout="common"
                path="/peticiones-reclamos"
                title="Peticiones y Reclamos"
                exact
                component={Tickets}
                blockedRoles={[]}
                permissionRoles={[
                    'ondemand',
                    'alumno',
                    'visita',
                    'docente',
                    'academico',
                ]}
            /> */}
            <CommonRoute
                layout="common"
                path="/loading/:rol"
                exact
                component={LoadingPage}
                blockedRoles={[]}
                permissionRoles={[
                    'ondemand',
                    'alumno',
                    'visita',
                    'docente',
                    'academico',
                ]}
            />
            <CommonRoute
                layout="common"
                path="/recomendaciones"
                exact
                component={IA}
                blockedRoles={[]}
                permissionRoles={[
                    'ondemand',
                    'alumno',
                    'visita',
                    'docente',
                    'academico',
                ]}
            />
            <CommonRoute
                layout="common"
                path="/competencia"
                exact
                component={Dracma}
                blockedRoles={[]}
                permissionRoles={[
                    'ondemand',
                    'alumno',
                    'visita',
                    'docente',
                    'academico',
                ]}
            />
            <CommonRoute
                layout="common"
                path="/networking"
                title="Directorio"
                exact
                component={Directory}
                blockedRoles={[]}
                permissionRoles={[
                    'ondemand',
                    'alumno',
                    'visita',
                    'docente',
                    'academico',
                ]}
            />
            <CommonRoute
                layout="common"
                path="/notificaciones"
                title="Notificaciones"
                exact
                component={Notification}
                blockedRoles={[]}
                permissionRoles={[
                    'ondemand',
                    'alumno',
                    'visita',
                    'docente',
                    'academico',
                ]}
            />
            <CommonRoute
                layout="common"
                path="/workshops"
                title="Cursos abiertos"
                exact
                component={Workshops}
                blockedRoles={['visita']}
                permissionRoles={['ondemand', 'alumno', 'docente', 'academico']}
            />
            <CommonRoute
                layout="course"
                path="/curso/:id"
                exact
                component={Course}
                blockedRoles={[]}
                permissionRoles={[
                    'ondemand',
                    'alumno',
                    'visita',
                    'docente',
                    'academico',
                ]}
            />
            <CommonRoute
                layout="common"
                path="/support"
                title="Tutoriales de ayuda"
                exact
                component={Support}
                blockedRoles={[]}
                permissionRoles={[
                    'ondemand',
                    'alumno',
                    'visita',
                    'docente',
                    'academico',
                ]}
            />
            <CommonRoute
                layout="common"
                path="/biblioteca"
                title="Biblioteca"
                exact
                component={Content}
                blockedRoles={[]}
                permissionRoles={[
                    'ondemand',
                    'alumno',
                    'visita',
                    'docente',
                    'academico',
                ]}
            />
            <CommonRoute
                layout="common"
                path="/asistencia"
                exact
                component={Asistencia}
                blockedRoles={['academico', 'docente', 'visita']}
            />
            <CommonRoute
                layout="common"
                path="/biblioteca/:id"
                exact
                component={Microlearnings}
                blockedRoles={[]}
                permissionRoles={[
                    'ondemand',
                    'alumno',
                    'visita',
                    'docente',
                    'academico',
                ]}
            />
            <CommonRoute
                layout="common"
                path="/favoritos"
                title="Favoritos"
                exact
                component={MyFavs}
                blockedRoles={[]}
                permissionRoles={[
                    'ondemand',
                    'alumno',
                    'visita',
                    'docente',
                    'academico',
                ]}
            />
            <CommonRoute
                layout="chat"
                path="/networking/chat"
                exact
                component={Chat}
                blockedRoles={[]}
                permissionRoles={[
                    'ondemand',
                    'alumno',
                    'visita',
                    'docente',
                    'academico',
                ]}
            />

            <CommonRoute
                layout="chat"
                path="/comunidad"
                exact
                component={ChatCommunity}
                blockedRoles={['ondemand']}
                permissionRoles={['alumno', 'docente']}
            />
            <CommonRoute
                layout="chat"
                path="/chat"
                exact
                component={ChatV2}
                blockedRoles={['ondemand', 'visita', 'academico']}
                permissionRoles={['alumno', 'docente']}
            />

            <CommonRoute
                layout="common"
                path="/eventos"
                title="Eventos"
                exact
                component={Events}
                blockedRoles={[]}
                permissionRoles={[
                    'ondemand',
                    'alumno',
                    'visita',
                    'docente',
                    'academico',
                ]}
            />

            <CommonRoute
                layout="common"
                path="/evento"
                title="Eventos"
                exact
                component={Events}
                blockedRoles={[]}
                permissionRoles={[
                    'ondemand',
                    'alumno',
                    'visita',
                    'docente',
                    'academico',
                ]}
            />

            <CommonRoute
                layout="common"
                path="/blog"
                exact
                component={Blog}
                blockedRoles={[]}
                permissionRoles={[
                    'ondemand',
                    'alumno',
                    'visita',
                    'docente',
                    'academico',
                ]}
            />
            <CommonRoute
                layout="common"
                path="/blog/:id"
                exact
                component={Article}
                blockedRoles={[]}
                permissionRoles={[
                    'ondemand',
                    'alumno',
                    'visita',
                    'docente',
                    'academico',
                ]}
            />
            <CommonRoute
                layout="common"
                path="/mis-referidos"
                exact
                component={MyReferrals}
                blockedRoles={['academico']}
                permissionRoles={['ondemand', 'alumno', 'visita', 'docente']}
            />
            {/* <CommonRoute
        layout="common"
        path="/cumbre"
        title="Cumbre"
        exact
        component={Summit}
        blockedRoles={["academico"]}
        permissionRoles={["alumno"]}
      /> */}
            {/* Errores */}
            <ErrorRoute
                layout="common"
                path="/not-found"
                exact
                component={NotFound}
                blockedRoles={[]}
                permissionRoles={[
                    'ondemand',
                    'alumno',
                    'visita',
                    'docente',
                    'academico',
                ]}
            />
            {/* Redirect default */}
            <Redirect path="*" to="/not-found" />
        </Switch>
    );
};

export default React.memo(Routes);
