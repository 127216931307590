import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import { IconButton } from '@mui/material';
import { Text } from '../Texts';
import { ChatContext } from '../../../contexts/chat/ChatProvider';
import { getUserByRepoPartnerId } from '../../../redux/api/chatAPI/user';

const ChatToTeacher = (props) => {
    const { value } = props;
    const { handleChat } = useContext(ChatContext);

    //Chat students of subject
    const partnerId = value.partner_id ? value.partner_id : value.id;

    //Chat
    // const handleChat = async (partnerId) => {
    //     const user = await getUserByRepoPartnerId(partnerId);

    //     if (user.statusCode === 404) {
    //         console.info('El usuario no se encuentra registrado en el chat');
    //         return;
    //     }

    //     createRoom(user);
    // };

    return (
        <BtnSocial
            onClick={() => handleChat(value?.repo_id)}
            variant="outlined"
        >
            <ForumRoundedIcon />{' '}
            <Text fontSize="0.8rem" fontWeight="900" color="#ffff">
                Chat
            </Text>
        </BtnSocial>
    );
};

export default ChatToTeacher;

const BtnSocial = styled(IconButton)`
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    background: #b31d15;
    border-radius: 4px;

    &.MuiIconButton-root:hover {
        background-color: #b31d15;
    }

    svg {
        color: #ffff;
        font-size: 22px;
        margin-right: 1rem;
    }
`;
