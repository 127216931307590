import React, { useMemo, useState, useEffect, useContext } from 'react';
import { Card, IconButton } from '@mui/material';
import styled from 'styled-components';
// import useMessages from '../../../../hooks/teacher/useMessages';
import ErrorResponse from '../../../common/ErrorResponse';
import SimpleLoading from '../../../common/SimpleLoading';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MessageTeacher from '../../../ui/organisms/teacher/messages/Message';
import { Text } from '../../../common/Texts';
import { useHistory } from 'react-router-dom';
import { ChatContext } from '../../../../contexts/chat/ChatProvider';

const MessagesTeacher = (props) => {
    const { id } = props;

    const { notifications } = useContext(ChatContext);

    // const { handleMessages } = useMessages(id);
    const history = useHistory();

    const [currentFilter, setCurrentFilter] = useState({
        index: 0,
        name: 'Por defecto',
    });

    useEffect(() => {}, []);

    const sortedMessages = useMemo(() => {
        return notifications?.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
    }, [notifications]);

    if (!notifications) {
        return (
            <LoadingWrapper>
                <SimpleLoading />
            </LoadingWrapper>
        );
    }

    return (
        <MessagesWrapper>
            <Header>
                <Text color="#555555" fontWeight="700" fontSize="1.2rem">
                    Mensajes
                </Text>
                <IconButton
                    onClick={() => history.push('/chat')}
                    title="Ir al chat"
                    size="large"
                >
                    <ExitToAppIcon />
                </IconButton>
            </Header>
            {notifications.length > 0 ? (
                <List>
                    {currentFilter.index === 0 &&
                        sortedMessages.map((messages) => (
                            <MessageTeacher values={messages} />
                        ))}
                </List>
            ) : (
                <ErrorResponse message="No hay ningún mensaje pendiente" />
            )}
        </MessagesWrapper>
    );
};

export default MessagesTeacher;

const MessagesWrapper = styled(Card)`
    display: flex;
    row-gap: 0px;
    flex-direction: column;
    height: auto;
    border-radius: 2rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 10px 20px;
`;

const List = styled.div`
    height: 200px;
    overflow-y: auto;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const LoadingWrapper = styled(Card)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;
