import React, { createContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    createUser,
    getUser,
    getUserByRepoPartnerId,
} from '../../redux/api/chatAPI/user';
import { useSelector } from 'react-redux';
import { socketIO } from '../../helpers/connectionSocket.helper';
import { userAdapter } from '../../pages/chatV2/adapters';
import { deleteNotificationRequest } from '../../redux/api/chat-V2/http/notification';
import { axiosErrorHanlder } from '../../utils/error.handler';
import GenericSnackbar from '../../components/common/GenericSnackbar';

export const ChatContext = createContext([]);

export const ChatProvider = ({ children }) => {
    const email = useSelector((state) => state?.auth?.user?.personal_email);
    const userData = useSelector((state) => state?.auth?.user);

    const history = useHistory();

    const pageRef = useRef(1);

    const [user, setUser] = useState(null);
    const [messages, setMessages] = useState([]);
    const [rooms, setRooms] = useState(null);
    const [roomMessages, setRoomMessages] = useState(null);
    const [currentRoom, setCurrentRoom] = useState(null);
    const [status, setStatus] = useState(null);
    const [socketId, setSocketId] = useState(null);
    const [latestMessage, setLatestMessage] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [reciverConnection, setReciverConnection] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);

    useEffect(() => {
        if (!!email && !!userData) {
            getChatUser(email);
        }
    }, [email, userData]);

    // * ============ SOCKET EVENTS LISTENERS AND SUBSCRIPTIONS ============

    useEffect(() => {
        if (!!user?.id) {
            socketIO.connect();
            setUserConnection();

            socketIO.emit('setUserStatus', {
                user_id: user?.id,
                status: true,
            });

            socketIO.on('userConnected', (isConnected) => {
                setStatus(isConnected);
                socketIO.connect();
            });
            socketIO.on('userDisconnected', (isConnected) => {
                setStatus(isConnected);
            });
            getNotifications(user?.id);
            getNotification();
        }

        return () => {
            socketIO.disconnect();
        };
    }, [user?.id]);

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    // * ============ SOCKET EVENTS ============

    const setUserConnection = () => {
        socketIO.emit('setUserOnline', { user_id: user?.id });
    };

    const getRoomMessages = (roomId) => {
        socketIO.emit('getMessages', { room_id: roomId });
    };

    const checkUserStatus = (userId) => {
        socketIO.emit('getConnectionStatus', userId);
        socketIO.on('getConnectionStatus', (status) => {
            setStatus(status);
        });
    };

    const setMessagesAsSeen = (messages, reciverSocketId) => {
        socketIO.emit('setMessagesAsSeen', {
            messages: messages,
            socket_id: reciverSocketId,
        });
    };

    const getNotifications = (reciver_id) => {
        socketIO.emit('getNotifications', {
            reciver: reciver_id,
        });
        socketIO.on('getNotifications', (notifications) => {
            setNotifications(notifications);
        });
    };

    const getNotification = () => {
        socketIO.on('notification', (notifications) => {
            setNotifications(notifications);
        });
    };

    const createRoom = (participant) => {
        const payload = {
            created_by: user?.id,
            participants: [
                {
                    user_id: user?.id,
                    name: user?.name,
                },
                {
                    user_id: participant?.id ?? participant?._id,
                    name: participant?.name,
                },
            ],
        };

        socketIO.emit('create-room', payload);
        socketIO.on('create-room', (room) => {
            history.push(
                `/chat?room=${Array.isArray(room) ? room[0]?._id : room?._id}`
            );
        });
    };

    // * ============ HTTP API CALL FUNCTIONS ============

    const getChatUser = async (email) => {
        const user = await getUser({ email });

        if (user.statusCode === 404) {
            const isCreated = await createChatUser();
            isCreated ? getChatUser(email) : console.error('User not created');
            return;
        }

        setUser(userAdapter(user));
    };

    const createChatUser = async () => {
        const payload = {
            name: userData?.name,
            email: userData?.personal_email,
            origin: 'acropolis',
            online: true,
            profile_photo: userData?.foto,
            repo_partner_id: userData?.repo_id,
            roles: userData?.roles,
        };

        const newUser = await createUser(payload);

        if (newUser.statusCode >= 400) {
            console.error('Error:', newUser);
            return false;
        }

        console.info('Status', newUser);
        return true;
    };

    const deleteNotification = async (notificationId) => {
        const request = await deleteNotificationRequest(notificationId);

        if (request.error) {
            return false;
        }

        setNotifications(
            notifications.filter(
                (notification) => notification._id !== notificationId
            )
        );
        return true;
    };

    const handleChat = async (partnerId) => {
        const user = await getUserByRepoPartnerId(partnerId);

        if (user.statusCode === 404) {
            setOpenSnackbar(true);
            return;
        }

        createRoom(user);
    };

    const values = {
        user,
        rooms,
        status,
        pageRef,
        messages,
        socketId,
        currentRoom,
        roomMessages,
        latestMessage,
        notifications,
        reciverConnection,
        setReciverConnection,
        deleteNotification,
        setMessagesAsSeen,
        setLatestMessage,
        getNotifications,
        setOpenSnackbar,
        getRoomMessages,
        checkUserStatus,
        setRoomMessages,
        setCurrentRoom,
        setMessages,
        setSocketId,
        handleChat,
        createRoom,
        setRooms,
    };

    return (
        <>
            <ChatContext.Provider value={values}>
                <GenericSnackbar
                    open={openSnackbar}
                    vertical="top"
                    severity="error"
                    message="No se pudo crear el chat, contacte con su asesor académico"
                    handleClose={handleCloseSnackbar}
                />
                {children}
            </ChatContext.Provider>
        </>
    );
};
