import React, { useState } from 'react';
import { Modal } from '@mui/material';
import { Icon } from '@iconify/react';
import {
    CommentText,
    CommentWrapper,
    DividerTitulationModal,
    RowAlert,
    WrapperModal,
} from '../../../../styled-components';
import { Text } from '../../../common/Texts';
import InputPDFDocument from './InputPDFDocument';
import DownloadButton from './DownloadButton';
import moment from 'moment';

const UpdateDocumentsModal = (props) => {
    const {
        open,
        handleCloseModal,
        title,
        documentId,
        handleSave,
        setLoading,
        openSnackbarText,
        comments,
        dateReview,
    } = props;

    const [file, setFile] = useState(null);

    const handleSaveFile = () => {
        if (!!file) {
            handleSave(file, documentId);
            handleCloseModal(true);
        }
    };

    const convertToLocalTime = (date, timezone) => {
        const zoneTime = moment.tz(date, timezone);
        const localTime = zoneTime.clone().tz(moment.tz.guess());
        return localTime.format('DD/MM/YYYY _ HH:mm');
    };

    const dateReviewLocalTime = convertToLocalTime(dateReview, 'UTC');

    return (
        <>
            <Modal onClose={handleCloseModal} open={open}>
                <WrapperModal
                    widthModal="550px"
                    maxHeightModal="80vh"
                    gapModal="1rem"
                    topModal="50%"
                    leftModal="50%"
                >
                    <Text
                        fontSize="20px"
                        fontWeight={700}
                        color="#B31D15"
                        textAlign="center"
                        lineHeight="24px"
                    >
                        Actualizar documentación
                    </Text>

                    <DividerTitulationModal />

                    <Text
                        fontSize="16px"
                        fontWeight={500}
                        color="#222222"
                        textAlign="left"
                    >
                        Archivo rechazado:
                    </Text>
                    <DownloadButton
                        label={'Descargar archivo'}
                        idFile={documentId}
                        nameFile={title}
                        setLoading={setLoading}
                        openSnackbarText={openSnackbarText}
                    />
                    <Text
                        fontSize="16px"
                        fontWeight={500}
                        color="#222222"
                        textAlign="left"
                    >
                        Comentarios:
                    </Text>
                    <CommentWrapper>
                        <CommentText>{comments}</CommentText>
                        <Text
                            fontSize="13px"
                            fontWeight={500}
                            color="#616161"
                            textAlign="right"
                            lineHeight="15px"
                        >
                            {dateReviewLocalTime}
                        </Text>
                    </CommentWrapper>

                    <RowAlert>
                        <Icon
                            icon="pepicons-pencil:exclamation-circle"
                            width="24"
                            height="24"
                        />
                        <Text
                            fontSize="16px"
                            fontWeight={700}
                            color="#B31D15"
                            textAlign="left"
                            fontStyle="italic"
                            lineHeight="25px"
                        >
                            Recuerda que sólo puedes subir el documento en
                            formato PDF.{' '}
                        </Text>
                    </RowAlert>

                    <InputPDFDocument
                        file={file}
                        setFile={setFile}
                        handleSaveFile={handleSaveFile}
                    />
                </WrapperModal>
            </Modal>
        </>
    );
};

export default UpdateDocumentsModal;
