export function axiosErrorFormatter(error) {
    const errorDetails = {
        // name: error.response.name,
        statusCode: error.response.status ?? 'UNKNOWN_ERROR',
        message: error.message,
        data: error.response.data,
        error: true,
    };

    return errorDetails;
}

export function axiosErrorHanlder(code) {
    const cases = {
        200: 'Success', // Default response case
        400: 'Bad request',
        401: 'Unauthorized',
        403: 'Forbidden',
        404: 'Not found',
        422: 'Unprocessable entity',
        500: 'Internal server error',
        UNKNOWN_ERROR: 'An unknown error occurred',
        ERR_BAD_REQUEST: 'Bad request',
    };

    return cases[code] || cases['200'];
}
