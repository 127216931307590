import React, { useState } from 'react';
import styled from 'styled-components';
import AchievementCardModal from './AchievementCardModal';
import ShareIcon from '@mui/icons-material/Share';
import { Button } from '@mui/material';

const AchievementCard = (props) => {
    const { value } = props;

    // STATE
    const [isOpen, setIsOpen] = useState(false);

    // FUNCTIONS
    function handleOpenModal() {
        setIsOpen(true);
    }

    function handleCloseModal() {
        setIsOpen(false);
    }

    return (
        <>
            <AchievementCardContainer onClick={handleOpenModal}>
                <Body>
                    <FakeImg src={value?.badge_image} />
                    <Type type={value?.badge_type}>{value?.badge_type}</Type>
                    <Name>{value?.badge_name}</Name>
                    <Description>{value?.badge_description}</Description>
                </Body>
                <Footer>
                    <ButtonFake className="marginL borderRadius">
                        <ShareIcon />
                    </ButtonFake>
                    <div />
                    {/* <ButtonFake
                        className="marginR"
                        variant="contained"
                        color="primary"
                    >
                        Ver
                    </ButtonFake> */}
                </Footer>
            </AchievementCardContainer>
            <AchievementCardModal
                isOpen={isOpen}
                handleCloseModal={handleCloseModal}
                achievement={value}
            />
        </>
    );
};

export default AchievementCard;

const AchievementCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    row-gap: 1rem;
    border-radius: 20px;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    margin-top: 1rem;
`;

const FakeImg = styled.img`
    width: 100px;
    height: auto;
`;

const Type = styled.span`
    font-size: 0.8rem;
    background-color: ${(props) =>
        props.type === 'Certificado académico'
            ? '#5e80db'
            : props.type === 'Insignia Académica'
              ? '#b31d15'
              : props.type === 'KNOWLEDGE INFLUENCER'
                ? '#b75e26'
                : '#1e8065'};
    color: #ffffff;
    border-radius: 50px;
    padding: 0.25rem 0.5rem;
`;

const Name = styled.h2`
    font-size: 1rem;
    font-weight: 700;
    /* height: 4rem; */
    text-transform: uppercase;
    color: #222222;
`;

const Description = styled.span`
    font-size: 0.8rem;
    color: #616161;
`;

const Body = styled.div`
    display: flex;
    padding: 2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
`;

const Footer = styled.div`
    height: 60px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #fafafa;
    align-items: center;
    overflow: hidden;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    .marginL {
        margin-left: 1rem;
    }
    .marginR {
        margin-right: 1rem;
    }
    .borderRadius {
        border-radius: 50%;
    }
`;

const ButtonFake = styled(Button)`
    svg {
        color: #a8a8a8;
    }
`;
