import styled from 'styled-components';
import CommonButton from '../../../components/common/CommonButton';

const ResourceCard = (props) => {
    const { resourceName, resourceLink, tutorialLabel, tutorialLink } = props;

    return (
        <Container>
            <h1>BIBLIOTECA {resourceName}</h1>
            <p>
                Accede a recursos académicos y científicos en formato digital.
                Haz clic en el botón, y conoce toda la bibliografía de{' '}
                {resourceName}.
            </p>
            <CommonButton
                label={`Ingresar a ${resourceName}`}
                variant="filled"
                onClick={() => window.open(resourceLink)}
            />
            <label>¿Necesitas ayuda?</label>
            <a href={tutorialLink} target="_blank">
                Tutorial: <b>"{tutorialLabel}"</b>
            </a>
        </Container>
    );
};

export default ResourceCard;

const Container = styled.div`
    width: calc(100% - 48px);
    height: 217px;
    border: 1px solid #b31d15;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    padding: 24px;

    h1 {
        font-size: 20px;
        font-weight: 700;
        color: #b31d15;
    }

    p {
        font-weight: 400;
        font-size: 16px;
        color: #1d1d18;
    }

    label {
        font-weight: 700;
        font-size: 14px;
        color: #666;
    }

    a {
        font-weight: 400;
        font-size: 14px;
        color: #b31d15;

        b {
            font-weight: 700;
            text-decoration: underline;
        }
    }

    @media (width < 768px) {
        height: fit-content;
        gap: 1rem;
    }
`;
