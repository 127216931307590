import { useEffect, useState } from 'react';
import styled from 'styled-components';

// Components
import FormWsGraduation from '../academicInfo/components/workshops/FormWsGraduation';

// Material UI
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventNoteIcon from '@mui/icons-material/EventNote';
import LocationIcon from '@mui/icons-material/LocationOn';

const CardInternationalWorkshop = (props) => {
    const { workshop, handleNewGetWs, ws, myWsGraduations, link } = props;

    const [isReserved, setIsReserved] = useState(false);

    useEffect(() => {
        const handleCheck = () => {
            myWsGraduations.forEach((element) => {
                if (element.workshop_lugar === workshop.country) {
                    setIsReserved(true);
                }
            });
        };
        handleCheck();
        // eslint-disable-next-line
    }, []);

    return (
        <CustomCard>
            <Header img={workshop?.img}>
                <Tag>
                    <p>Workshop internacional |</p>
                    <span>{workshop.country}</span>
                </Tag>
            </Header>
            <Body>
                <Title>{workshop?.title}</Title>
                <Item>
                    <EventAvailableIcon />
                    <label>Inicio:</label>
                    <span>{workshop?.date}</span>
                </Item>
                <Item>
                    <EventBusyIcon />
                    <label>Cierre de inscripción:</label>
                    <span>{workshop?.untilDate}</span>
                </Item>
                <Item>
                    <EventNoteIcon />
                    <label>Reunión informativa:</label>
                    <span>{workshop?.briefing}</span>
                </Item>
                <Item>
                    <LocationIcon />
                    <label>Ubicación:</label>
                    <span>
                        {workshop?.location} - {workshop?.country}
                    </span>
                </Item>
            </Body>
            <Footer>
                {/* <IconButton
                    target="_blank"
                    href="https://drive.google.com/file/d/1bM4UDmQH9Zo9kmoKiuIb2dY009lxUBhT/view?usp=sharing"
                >
                    <AddCircleOutlineIcon />
                    Ver más info
                </IconButton> */}
                <FormWsGraduation
                    handleNewGetWs={handleNewGetWs}
                    isReserved={isReserved}
                    ws={ws}
                    id={workshop.id}
                    link={link}
                    disabled={workshop.disabled}
                />
            </Footer>
        </CustomCard>
    );
};

export default CardInternationalWorkshop;

const CustomCard = styled.div`
    width: 385px;
    border-radius: 20px;
    box-shadow:
        0px 12px 24px -15px #0000001a,
        0px 0px 10px -6px #00000040;
`;

const Header = styled.div`
    width: 100%;
    height: 140px;
    background-image: ${(p) => `url(${p.img})`};
    background-size: cover;
    background-position: 60% center;
    position: relative;
    background-repeat: no-repeat;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: hidden;
`;

const Body = styled.div`
    padding: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
`;

const Tag = styled.div`
    height: 32px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    display: flex;
    width: fit-content;
    align-items: center;
    border-radius: 20px 0 0 0;
    height: fit-content;
    background-color: #fff;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
    gap: 4px;

    p {
        font-size: 14px;
        font-weight: 600;
        color: #616161;
    }

    span {
        font-size: 14px;
        font-weight: 700;
        color: #b31d15;
    }
`;

const Title = styled.h2`
    font-size: 1.2rem;
    color: #222222;
    text-align: left;
`;

const Item = styled.div`
    display: flex;
    align-items: center;
    gap: 0.3rem;
    color: #222222;
    font-size: 14px;

    svg {
        color: #b31d15;
        font-size: 1.5rem;
    }
    label {
        font-weight: 700;
    }
    span {
        font-weight: 400;
    }
`;

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
    background-color: #fafafa;
`;

const IconButton = styled.a`
    display: flex;
    border: solid 1px #b31d15;
    border-radius: 50px;
    height: 44px;
    padding: 12px 16px;
    box-sizing: border-box;
    gap: 8px;
    align-items: center;
    color: #b31d15;
    font-size: 1rem;
    cursor: pointer;

    svg {
        color: #b31d15;
        font-size: 1.4rem;
    }
`;
